import { useModal } from '../context/modal';
import classes from 'styles/NewHomepage.module.scss';
import { useHistory } from 'react-router-dom';
import { useAdmin } from 'context/admin';
import { useEffect } from 'react';
import AdminLogin from 'modals/AdminLogin';

export const AdminHomePage = ({}) => {
	const { openModal } = useModal();
	const { Admin, Atoken } = useAdmin();
	const history = useHistory();
	useEffect(() => {
		// console.log({ Partner, Ptoken });
		if (Admin && Atoken) {
			history.push('/dashboard');
		}
	}, [Admin, Atoken]);
	return (
		<>
			<main>
				<div className={classes.landing_screen}>
					<h1 className={classes.first_text}>Welcome To Encryptus</h1>
					<p className={classes.first_text2}> Partner Login</p>
					<section className={classes.Btn_div}>
						<button
							className={classes.become_client}
							onClick={() => openModal(AdminLogin)}
						>
							Admin Login
						</button>
					</section>
				</div>
				{/* <img src={fading_image} alt="background_logo" className="fading_logo" /> */}
			</main>
		</>
	);
};
