import React from 'react';
import classes from 'styles/CryptoDetails.module.scss';
import { DATE_CONV } from 'utils/DateConv';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { copyText } from 'utils/CopyContent';
// import { Link } from 'react-router-dom';
// import { useModal } from 'context/modal';

function PcryptoDepositDetailsModal({
	transactionData,
	CurrencyType,
}: any) {
	// console.log({
	//     transactionData,
	// });

	// const { closeModal } = useModal();
	// const url = (tagger_id: string) => {
	//   // closeModal();
	//   return `/admin/user/${tagger_id}`;
	// };

	return (
		<div className={classes.detailsContainer}>
			<h1>Crypto Deposit Details</h1>
			<section className={classes.box}>
				<>
					<div>
						<h4>Transaction Status</h4>
						<p
							onDoubleClick={() =>
								copyText(transactionData?.transc_status?.toUpperCase())
							}
							className={
								transactionData?.transc_status === 'approved'
									? 'textStatusApproved'
									: transactionData?.transc_status === 'pending'
									? 'textStatusPending'
									: 'textStatusRejected'
							}
						>
							{transactionData?.transc_status?.toUpperCase()}
						</p>
					</div>

					<div>
						<h4>Amount</h4>
						<p
							onDoubleClick={() =>
								copyText(
									CURRENCY_CONV.cryptoAmtConv(
										transactionData?.received_amt,
									),
								)
							}
						>
							{CURRENCY_CONV.cryptoAmtConv(transactionData?.received_amt)}
						</p>
					</div>

					<div>
						<h4>Crypto Asset</h4>
						<p
							onDoubleClick={() =>
								copyText(transactionData?.received_amt_curr)
							}
						>
							{transactionData?.received_amt_curr}
						</p>
					</div>

					<div>
						<h4>Source Address</h4>
						<p
							onDoubleClick={() =>
								copyText(transactionData?.source_address)
							}
							style={{
								paddingRight: '30px',
							}}
						>
							{transactionData?.source_address}
						</p>
					</div>

					<div
						style={{
							paddingRight: '10px',
						}}
					>
						<h4>Transaction Hash</h4>
						<p
							onDoubleClick={() =>
								copyText(transactionData?.transaction_hash)
							}
						>
							{transactionData?.transaction_hash}
						</p>
					</div>

					<div>
						<h4>Flagged</h4>
						<p
							onDoubleClick={() =>
								copyText(!transactionData?.flagged ? 'CLEAN' : 'DIRTY')
							}
							className={
								transactionData?.flagged === false
									? 'textStatusApproved'
									: 'textStatusRejected'
							}
						>
							{!transactionData?.flagged ? 'CLEAN' : 'DIRTY'}
						</p>
					</div>

					<div>
						<h4>Transaction Date - Time</h4>
						<p
							onDoubleClick={() =>
								copyText(
									DATE_CONV.dateTimeConvert(transactionData?.createdAt),
								)
							}
						>
							{DATE_CONV.dateTimeConvert(transactionData?.createdAt)}
						</p>
					</div>
				</>
			</section>
		</div>
	);
}

export default PcryptoDepositDetailsModal;
