// import { AnyMessageParams } from 'yup/lib/types';

export const API_HOST = process.env.REACT_APP_SERVER_HOST;
export const Server_Host = process.env.REACT_APP_AWS_SERVER_HOST;

export enum USER_ROLES {
	Fintech = 'fintech',
	Finance = 'finance',
	Operations = 'operations',
	Marketing = 'marketing',
	SuperAdmin = 'admin',
}

export enum ADMIN_ROLES {
	Fintech = 'fintech',
	Finance = 'finance',
	Operations = 'operations',
	Marketing = 'marketing',
}

export enum FIAT_CURR {
	USD = 'USD',
	AED = 'AED',
	EUR = 'EUR',
	GBP = 'GBP',
}

export enum EDIT_USER_BALANCE_FIAT_CURR {
	USD = 'USD',
	EUR = 'EUR',
	GBP = 'GBP',
}

export enum CRYPTO_CURR {
	BTC = 'BTC',
	ETH = 'ETH',
	USDT = 'USDT',
	USDC = 'USDC',
	Matic = 'Matic',
}

export enum LIMINAL_SUPPORTED_CURR {
	BTC = 'btc',
	ETH = 'eth',
	USDT = 'usdet',
}

export const ADMIN_ACTIONS = {
	FA: 'Full Access (Superadmin)',
	VFDT: 'View Fiat Deposit Tickets',
	VFWT: 'View Fiat Withdraw Tickets',
	VCWT: 'View Crypto Withdraw Tickets',
	VCGT: 'View Crypto Buy Tickets',
	VCST: 'View Crypto Sell Tickets',
	VCDT: 'View Crypto Deposit Tickets',
	UCDT: 'Update Crypto Deposit Tickets',
	UFDT: 'Update Fiat Deposit Tickets',
	UFWT: 'Update Fiat Withdraw Tickets',
	UCWT: 'Update Crypto Withdraw Tickets',
	UCGT: 'Update Crypto Buy Tickets',
	UCST: 'Update Crypto Sell Tickets',
	BAA: 'Bank Applications Access(view,approve,reject)',
	WA: 'Wallet Access(View, Enable, Block, see TRM reports)',
	SDA: 'Sales Dashboard Access(Sales or Marketing Personal)',
	GAUD: 'Get All User Details',
};

export const ROUTES = {
	// PARTNERS ROUTES+++++++++++++++++++++
	Partners_Generate_Token: `${Server_Host}/partners/generate/token`,
	Partners_Login: `${Server_Host}/partners/login`,
	Partners_Info: `${Server_Host}/partners/accountInfo`,
	Partners_Registor: `${Server_Host}/partners/onboarding`,
	Partners_Refresh_Token: `${Server_Host}/partners/refresh/token`,
	Partners_Logout: `${Server_Host}/partners/logout`,
	Partners_Mobile_topups_Order: `${Server_Host}/topup/orders`,
	Partners_GiftCards: `${Server_Host}/payout/giftcard/orders`,
	report_dashboard: `${Server_Host}/partners/report/dashboard`,

	Partners_FIAT_REPORT_TICKETS: `${Server_Host}/tickets/fiat/deposits`,
	Partners_CRYPTO_REPORT_TICKETS: `${Server_Host}/tickets/crypto/deposits`,
	Partners_FIAT_WITHDRAW_TICKETS: `${Server_Host}/tickets/fiat/withdrawals`,
	Partners_CRYPTO_WITHDRAW_TICKETS: `${Server_Host}/tickets/crypto/withdrawals`,
	Partners_CRYPTO_BALANCE_TICKETS: `${Server_Host}/tickets/crypto/balance`,
	Partners_ALL_TICKETS: `${Server_Host}/tickets/all`,

	Partners_BANK_LIST: `${Server_Host}/partners/forensics/fetchAll/whitelisted/BankAccount`,
	Partners_WALLET_LIST: `${Server_Host}/partners/forensics/fetchAll/whitelisted/WalletAddress`,
	Partners_FETCH_ALL_USERS: `${Server_Host}/partners/fetchall/user`,

	Partners_PAYOUT_GIFTCARD: `${Server_Host}/partners/payout/gitftcard/order`,
	Partners_PAYOUT_MOBILE_TOPUP: `${Server_Host}/partners/forensics/fetchAll/whitelisted/WalletAddress`,
	Partners_PAYOUT_ALL: `${Server_Host}/tickets/payout/all`,

	Partners_HELP_CENTER: `${Server_Host}/partners/help`,

	Admin_Info: `${Server_Host}/partners/accountInfo`,
	Admin_Refresh_Token: `${Server_Host}/partners/refresh/token`,
	Admin_Login: `${Server_Host}/partners/admin/login`,
	Admin_Logout: `${Server_Host}/partners/admin/logout`,
	Admin_Add: `${Server_Host}/partners/admin/add`,
	Admin_Margins: `${Server_Host}/partners/admin/globalmargin`,
	Admin_Update_Margin: `${Server_Host}/partners/admin/updateglobalmargin`,
	Admin_Providers: `${Server_Host}/partners/admin/providerconfig`,
	Admin_Update_Provider_Config: `${Server_Host}/partners/admin/updateproviderconfig`,
	Admin_Add_Provider_Config: `${Server_Host}/partners/admin/addproviderconfig`,

	Admin_Fetch_CDeposit_Ticket : `${Server_Host}/tickets/admin/cdeposits`,
	Admin_Fetch_CWithdrawal_Ticket : `${Server_Host}/tickets/admin/cwithdrawals`,

	Admin_CDeposit_Ticket_Req : `${Server_Host}/tickets/admin/cdTicket/finalApproval`,
	Admin_CWithdrawal_Ticket_Req : `${Server_Host}/tickets/admin/cwTicket/finalApproval`
};
