import React, { useRef, useState } from 'react';
import Filtericon from 'assets/filtersicon.svg';
import useOutsideClick from '../hooks/useOutsideClick';
import { FilterListItem } from '../interfaces';
import classes from '../styles/Filters.module.scss';

interface Props {
    filterList?: FilterListItem[];
    filters?: any;
    updateFilters?: (key: string, value: string) => void;
}

export default function Filters({ filterList, updateFilters, filters }: Props) {
    const [filterOpen, setFilterOpen] = useState(false);
    const filterRef = useRef(null);
    useOutsideClick(filterRef, () => setFilterOpen(false));
    return (
        <div ref={filterRef} className={classes.container}>
            <div onClick={() => setFilterOpen((p) => !p)} className={classes.button}>
                <h2>Filter</h2>
                <img src={Filtericon} alt="filter" className={classes.filtericon} />
            </div>
            {filterOpen && (
                <div className={classes.dropdown}>
                    {filterList?.map(({ field, label, options }) => (
                        <div className={classes.filter}>
                            <h6>{label || field}</h6>
                            {options.map(({ value, label }) => (
                                <div
                                    onClick={() => updateFilters?.(field, value)}
                                    className={classes.option}
                                    key={label}
                                >
                                    <input type="checkbox" checked={filters?.[field] === value} />
                                    <p>{label || value}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
