import classes from 'styles/AdminAccess.module.scss';
import axios from 'axios';
import { useEffect, useState } from 'react';
import AdminScreen from 'components/adminScreen';
import { ROUTES } from 'config';
import { useAdmin } from 'context/admin';
// import { usePartners } from 'context/partners';
import Button from 'components/Button';
import Arrowdown from 'assets/arrow-down-black.svg';
import { toast } from 'react-toastify';
interface MarginData {
	name: string;
	value: number;
}

interface ProductData {
	productName: string;
	marginParameters: MarginData[];
}
export default function AdminAcess() {
	const { Atoken, headers } = useAdmin();
	const [margins, setMargins] = useState<ProductData[]>([]);
	const [updatedMarginValue, setUpdatedMarginValue] = useState<number>();
	const PaymentTypes = [
		'Bank Wires',
		'Gift Cards',
		'Mobile Top-up',
		'Mobile Wallet',
	];
	const roles = ['fintech', 'marketing', 'Role 3', 'Role 4'];
	const handleRoleSelection = (selectedRole: string) => {
		setRole(selectedRole);
		setShowDropDown(false);
	};

	const [showDropdown, setShowDropDown] = useState(false);
	const [inputValues, setInputValues] = useState<string[]>(
		Array(PaymentTypes.length).fill(''),
	);
	const [role, setRole] = useState<string>('Select Role');
	const [fees, setFees] = useState<string[]>(
		Array(PaymentTypes.length).fill('0.00'),
	);
	const [feeInputValues, setFeeInputValues] = useState<string[]>(
		Array(PaymentTypes.length).fill(''),
	);

	const AddNewAdmin = async (data: any) => {
		try {
			console.log({ data });
			const response = await axios({
				method: 'POST',
				url: ROUTES.Admin_Add,
				data: data,
				headers: {
					'Content-Type': 'application/json',
					...headers,
				},
			});
			console.log({ response });
			toast.success('New Admin Added Successfully!');
		} catch (e: any) {
			console.log(e);
			toast.error(e.message);
		}
	};
	// ##############################################
	// 	the request params are
	// { productName: <productname>,
	//  marginParameter : {
	//    name : <name>
	//   value : <value>
	//   }
	// } (
	// ###############################################

	const fetchCurrentMargins = async () => {
		try {
			const response = await axios({
				method: 'GET',
				baseURL: ROUTES.Admin_Margins,
				headers: {
					Authorization: `Bearer ${Atoken}`,
				},
			});
			const Marginsdata = response.data.data;
			setMargins(Marginsdata);
			console.log(Marginsdata);
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		fetchCurrentMargins();
	}, []);
	const handleMarginUpdate = async (
		productName: string,
		parameterName: string,
	) => {
		const data = {
			productName: productName,
			marginParameter: {
				name: parameterName,
				value: updatedMarginValue,
			},
		};
		await UpdateMargin(data);
		fetchCurrentMargins();
	};
	const UpdateMargin = async (data: any) => {
		try {
			console.log({ data });
			const response = await axios({
				method: 'POST',
				url: ROUTES.Admin_Update_Margin,
				data: data,
				headers: {
					'Content-Type': 'application/json',
					...headers,
				},
			});
			toast.success('Margin Change Successful !');
			console.log({ response });
		} catch (e: any) {
			console.log(e);
			toast.error(e.message);
		}
	};

	const setValues = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const nameInput = document.getElementById('name') as HTMLInputElement;
		const emailInput = document.getElementById(
			'email',
		) as HTMLInputElement;
		const roleInput = document.getElementById('role') as HTMLInputElement;
		const passwordInput = document.getElementById(
			'password',
		) as HTMLInputElement;
		const data = {
			name: nameInput.value,
			email: emailInput.value,
			role: role,
			password: passwordInput.value,
		};
		console.log(data);
		await AddNewAdmin(data);
	};
	return (
		<AdminScreen
			className={classes.container1}
			style={{ height: 'auto' }}
		>
			<div
				className={classes.container}
				style={{
					color: 'white',
				}}
			>
				<div className={classes.upper}>
					<div>
						<h1 style={{ textAlign: 'center' }}>
							Set Product Commissions
						</h1>
						<div>
							{margins.map((product) => (
								<div key={product.productName} className={classes.table1}>
									<h3 style={{ marginBottom: '15px', fontSize: 'xl' }}>
										{product.productName.toUpperCase()}
									</h3>
									<table>
										<thead>
											<tr>
												<th>Margin Parameter</th>
												<th>Value</th>
												<th>Update Value</th>
											</tr>
										</thead>
										<tbody>
											{product.marginParameters.map((parameter) => (
												<tr key={parameter.name}>
													<td style={{ marginBottom: '10px' }}>
														{parameter.name}
													</td>
													<td style={{ marginBottom: '20px' }}>
														{parameter.value}
													</td>
													<td>
														<input
															type="number"
															id="margin_update"
															value={updatedMarginValue} // Bind the input value to state
															onChange={(e) =>
																setUpdatedMarginValue(
																	parseFloat(e.target.value),
																)
															}
														/>
													</td>
													<td>
														<Button
															onClick={() =>
																handleMarginUpdate(
																	product.productName,
																	parameter.name,
																)
															}
														>
															Submit
														</Button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className={classes.lower}>
					<div>
						<h1>Add New Admin</h1>
					</div>
					<div className={classes.lower2}>
						<div>
							<div>
								<label>Name</label>
							</div>
							<input
								style={{ position: 'relative' }}
								type="text"
								id="name"
								required
								placeholder="Name"
							/>
						</div>
						<div>
							<div>
								<label>Email</label>
							</div>
							<input
								style={{ position: 'relative' }}
								type="email"
								id="email"
								required
								placeholder="email"
							/>
						</div>
						<div>
							<label>Group</label>
							<div
								style={{
									width: '180px',
									height: '40px',
									borderRadius: '5px',
									padding: '5px',
									display: 'flex',
									alignItems: 'center',
									backgroundColor: 'white',
								}}
							>
								<input
									type="text"
									required
									value={role}
									onChange={(e) => setRole(e.target.value)}
									style={{
										flex: '1',
										border: 'none',
										outline: 'none',
									}}
								/>
								<button
									style={{
										flex: '1',
										width: '30px',
										height: '30px',
										border: 'none',
										cursor: 'pointer',
									}}
									onClick={() => setShowDropDown(!showDropdown)}
								>
									<img
										src={Arrowdown}
										alt="arrow-img"
										color="white"
										style={{
											padding: '4px',
											width: '100%',
											height: '100%',
											objectFit: 'contain',
											backgroundColor: 'white',
										}}
									/>
								</button>
							</div>
							{showDropdown && (
								<div className={classes.dropdown2}>
									{/* Dropdown options */}
									{roles.map((roleOption, index) => (
										<div
											key={index}
											onClick={() => handleRoleSelection(roleOption)}
										>
											{roleOption}
										</div>
									))}
								</div>
							)}
						</div>
						<div>
							<label>Password</label>
							<div
								style={{
									width: '200px',
									height: '40px',
									borderRadius: '5px',
									padding: '5px',
									display: 'flex',
									alignItems: 'center',
									backgroundColor: 'white',
								}}
							>
								<input
									type="password"
									id="password"
									style={{
										flex: '1',
										border: 'none',
										outline: 'none',
									}}
								/>
							</div>
						</div>
						<div>
							<br />
							<Button onClick={setValues}>Add Admin</Button>
						</div>
					</div>
				</div>
			</div>
		</AdminScreen>
	);
}
