import { useState } from 'react';
import Form from '../components/Forms';
import classes from '../styles/Login.module.scss';
import { FormProp, LoginForm } from '../interfaces';
import { ReactComponent as emailIcon } from '../assets/email.svg';
import { ReactComponent as keyIcon } from '../assets/key.svg';
import { ReactComponent as eyeIcon } from '../assets/eye.svg';
import Button from '../components/Button';
import { useModal } from '../context/modal';
import * as yup from 'yup';
import { useAdmin } from '../context/admin';

const entries: FormProp[] = [
	{
		name: 'email',
		label: 'E-Mail',
		icon: emailIcon,
		placeholder: '',
		type: 'email',
	},
	{
		name: 'password',
		label: 'Password',
		icon: keyIcon,
		placeholder: '',
		toolIcon: eyeIcon,
		type: 'password',
	},
];

const schema = yup.object().shape({
	email: yup.string().email().required(),
	password: yup.string().min(6).required(),
});

export default function AdminLogin() {
	const [loading, setLoading] = useState(false);
	const { closeModal } = useModal();
	const { login } = useAdmin();

	const handleSubmit = async (data: LoginForm) => {
		login(data, closeModal);
	};

	return (
		<div className={classes.container}>
			<h1 className={classes.heading}>Admin Login</h1>
			<Form
				setLoading={setLoading}
				onSubmit={handleSubmit}
				schema={schema}
				entries={entries}
			>
				<div className={classes.action}>
					<Button type="submit" loading={loading}>
						Login
					</Button>
				</div>
			</Form>
		</div>
	);
}
