import { createContext, FC, useContext, useState } from 'react';
import { ANY } from '../interfaces';

const NotificationContext = createContext({
    notifications: ANY,
    handleNotifications: (newNotification: any) => ANY,
});

export const NotificationProvider: FC = ({ children }) => {
    const [notifications, setNotifications] = useState<any>([]);

    const handleNotifications = (newNotification: any) => {
        setNotifications([...notifications, newNotification]);
    };

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                handleNotifications,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = () => useContext(NotificationContext);
