import React, { useState } from 'react';
import classes from '../styles/Register.module.scss';
import inputClasses from '../styles/InputField.module.scss';
import { FormProp, PartnerRegisterForm } from '../interfaces';
import Form from 'components/Forms';
import Button from '../components/Button';
import { useModal } from '../context/modal';
import * as yup from 'yup';
import 'react-phone-input-2/lib/style.css';
import passStrength from 'zxcvbn';
import { usePartners } from 'context/partners';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

const schema = yup.object().shape({
    enterpriseName: yup.string().required('Enterprise Name is required'),
    location: yup.string().required('Location is required'),
    email: yup.string().required('Email is required'),
    phone_no: yup
        .string()
        .required('Phone Number is Required')
        .min(8, 'Phone Number minimum length needs to be 8')
        .max(22, 'Phone Number maximum length is 22'),
});

export default function Pregister() {
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [passwordStrength, setPasswordStrength] = useState<number>(0);
    const [passwordError, setPasswordError] = useState<string>('');
    // eslint-disable-next-line
    const [country, setCountry] = useState<string>('ae');

    // const [sumSubLevelName, setSumSubLevelName] = useState('');
    const { closeModal } = useModal();
    const { register } = usePartners();

    const entries: FormProp[] = [
        [
            {
                label: 'Enterprise Name',
                name: 'enterpriseName',
                placeholder: 'Enterprise Name',
            },
            { name: 'location', placeholder: 'Location' },
        ],
        [
            {
                label: 'Enterprise Email',
                name: 'email',
                placeholder: 'Enterprise Email',
                type: 'email',
            },
            {
                label: 'Enterprise Phone Number',
                name: 'phone_no',
                phoneNumberInput: true,
                type: 'tel',
                country: country,
            },
        ],
    ];

    // useEffect(() => {
    //   checkLocation();
    // }, []);

    // const checkLocation = async () => {
    //   try {
    //     axios.defaults.withCredentials = true;
    //     const { data } = await axios.get(ROUTES.CHECK_LOCATION);
    //     if (data) {
    //       setCountry(data.shortName.toLowerCase());
    //       return;
    //     }
    //   } catch (error: any) {
    //     if (error?.response?.status === 403) {
    //       toast.error(`Thank you for visiting Encryptus. Unfortunately, you are visiting Encryptus from a country where
    //                   we do not offer our service. Please read our User Agreement for further information.

    //                   If you are not in a restricted country or a national or resident of a restricted country,
    //                   please contact our customer service for technical support at[*].`);
    //     }
    //   }
    // };

    const handleSubmit = (f: PartnerRegisterForm) => {
        console.log({
            loc: 'handleSubmit',
            f,
        });

        if (!passwordRegex.test(password)) {
            setPasswordError(
                () =>
                    'Password Must Contain More than 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character',
            );
        } else if (password !== confirmPassword) {
            setPasswordError(() => 'Passwords must match');
        } else {
            const passInfo: any = passStrength(password);

            if (passInfo && passInfo.Strength < 4) {
                setPasswordError(() => 'Please enter a strong password to proceed');
            } else {
                f.password = password;
                f.passwordConfirmation = password;
                // f.levelName = sumSubLevelName;

                console.log({
                    updatedF: f,
                });

                return register(f, closeModal);
            }
        }
    };

    // ? Method to handle Password field text input
    const handlePasswordChange = (password: any) => {
        setPassword(() => password);

        if (!passwordRegex.test(password)) {
            setPasswordError(
                () =>
                    'Password Must Contain More than 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character',
            );
        } else {
            setPasswordError(() => '');
        }

        let passInfo: any = passStrength(password);

        if (passInfo && passInfo.score) {
            setPasswordStrength(passInfo.score);
        }
    };
    // * End of handlePasswordChange()

    // ? Method to handle Confirm Password field text input
    const handleConfirmPasswordChange = (confirmPassword: any) => {
        setConfirmPassword(() => confirmPassword);

        if (confirmPassword !== password) {
            setPasswordError(() => 'Passwords must match');
        } else {
            setPasswordError(() => '');
        }
    };
    // * End of handleConfirmPasswordChange();

    // ? Method to handle the change in the radio button selection
    // const handleLevelNameChange = (event: any) => {
    //   setSumSubLevelName(event.target.value);
    // };
    // * End of handleLevelNameChange()

    return (
        <div className={classes.container}>
            <h1 className={classes.heading}>
                Enter Your Details to Register your Account
            </h1>
            <div className={classes.FormContainer}>
                <Form
                    setLoading={setLoading}
                    schema={schema}
                    onSubmit={handleSubmit}
                    entries={entries}
                >
                    <div
                        className={inputClasses.flex}
                        style={{
                            marginTop: '-10px',
                        }}
                    >
                        <div>
                            <div className={inputClasses.container}>
                                <label>Password</label>
                                <div className={inputClasses.inputContainer}>
                                    <div className={inputClasses.icon}></div>
                                    <input
                                        name="password"
                                        id="clean"
                                        placeholder=""
                                        type="password"
                                        value={password}
                                        onChange={(event) =>
                                            handlePasswordChange(event.target.value)
                                        }
                                        required
                                    />

                                    <div className={inputClasses.tool}></div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className={inputClasses.container}>
                                <label>Confirm Password</label>
                                <div className={inputClasses.inputContainer}>
                                    <div className={inputClasses.icon}></div>
                                    <input
                                        name="passwordConfirmation"
                                        id="clean"
                                        placeholder=""
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(event) =>
                                            handleConfirmPasswordChange(event.target.value)
                                        }
                                        required
                                    />
                                    <div className={inputClasses.tool}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className={classes.Para}>
                        Please do not use any part of your full name or email address, and
                        avoid using commonly used or guessable passwords.
                    </p>

                    <div
                        style={
                            {
                                // marginTop: '-12px',
                            }
                        }
                    >
                        <div>
                            <div className={classes.passwordStrengthSection}>
                                <div
                                    className={classes.passwordStrengthBar}
                                    style={{
                                        width: `${passwordStrength * 25}%`,
                                        backgroundColor:
                                            passwordStrength < 3
                                                ? 'red'
                                                : passwordStrength === 3
                                                    ? '#F6BE00'
                                                    : passwordStrength > 3
                                                        ? 'green'
                                                        : 'none',
                                    }}
                                />
                            </div>
                            <span>
                                Password Strength: &nbsp;
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                        color:
                                            passwordStrength < 3
                                                ? 'red'
                                                : passwordStrength === 3
                                                    ? '#F6BE00'
                                                    : passwordStrength > 3
                                                        ? 'green'
                                                        : 'none',
                                    }}
                                >
                                    {passwordStrength < 3
                                        ? 'WEAK'
                                        : passwordStrength === 3
                                            ? 'FAIR'
                                            : passwordStrength > 3
                                                ? 'STRONG'
                                                : ''}
                                </span>
                            </span>
                        </div>
                        <div>
                            <div className={classes.passErrSection}>
                                <span>{passwordError}</span>
                            </div>
                        </div>
                    </div>

                    {/* <div
            onChange={handleLevelNameChange}
            className={classes.levelSelectionSection}
          >
            <span>Are you an Individual or Institution ?</span>

            <div>
              <label>
                <input type="radio" value="Individual v2" name="levelName" />
                <span> Individual</span>
              </label>
              &nbsp; &nbsp; &nbsp;
              <label>
                <input type="radio" value="Institution v2" name="levelName" />
                <span> Institution</span>
              </label>
            </div>
          </div> */}

                    <div className={classes.inputSection}>
                        <label>
                            <input type="checkbox" required />
                            <p>
                                Please Agree To Our{' '}
                                <a
                                    href="https://encryptus.io/termsCondition"
                                    className={classes.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Terms and Conditions
                                </a>
                            </p>
                        </label>
                    </div>
                    <Button loading={loading} className={classes.submit}>
                        Register
                    </Button>
                </Form>
            </div>
        </div>
    );
}
