import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

interface Props {
	data?: any;
}

const COLORS = ['#D5B54A', '#E0E0E0', '#2F70E8'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent,
	index,
}: any) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text
			x={x}
			y={y}
			fill="white"
			textAnchor={x > cx ? 'start' : 'end'}
			dominantBaseline="central"
		>
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

export default function Piechart(props: Props) {
	if (!props.data) {
		return <h4>Piechart</h4>;
	}
	return (
		<PieChart width={325} height={200}>
			<Pie
				data={props.data}
				cx="50%"
				cy="50%"
				labelLine={false}
				label={renderCustomizedLabel}
				outerRadius={80}
				fill="#D5B54A"
				dataKey="value"
			>
				{props.data?.map((entry: any, index: any) => (
					<Cell
						key={`cell-${index}`}
						fill={COLORS[index % COLORS.length]}
					/>
				))}
			</Pie>
			<Legend
				iconType="circle"
				layout="vertical"
				align="right"
				verticalAlign="middle"
				height={36}
			/>
			<Tooltip />
		</PieChart>
	);
}
