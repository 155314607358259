import PartnerScreen from 'components/partnersScreen';
import { useEffect, useState } from 'react';
import classes from 'styles/Transaction.module.scss';
import { useModal } from 'context/modal';
import { ROUTES } from 'config';
// import CryptoDepositDetails from 'modals/CryptoDepositDetails';
// import DepositeTicket from 'modals/DepositeTicket';
import Button from 'components/Button';
import Filters from 'components/Filters';
import { FilterListItem } from 'interfaces';
import useFilters from 'hooks/useFilters';
import { useSearch } from 'context/search';
import Paginate from 'components/Paginate';
import { DATE_CONV } from 'utils/DateConv';
// import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { copyText } from 'utils/CopyContent';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import arrowDownBlack from 'assets/arrow-down-black.svg';
import Arrowdown from 'assets/Arrow - Down 4.png';
// import { usePartners } from 'context/partners';
import usePartnersPaginate from 'hooks/usePartnersPaginate';
import exportFromJSON from 'export-from-json';
import MobileToupsDetails from 'modals/MobileToupsDetails';
import { MenuDropDownFilter } from 'components/MenuDropDown';
import { Console } from 'console';
import { Controller, useForm } from 'react-hook-form';
import InputField from 'components/InputField';
import axios from 'axios';
import { toast } from 'react-toastify';
import { usePartners } from 'context/partners';

const filterList: FilterListItem[] = [
	{
		field: 'date',
		label: 'Date',
		options: [
			{
				value: 'Payout transactions - Failed',
				label: 'Payout transactions - Failed',
			},
		],
	},
	{
		field: 'product',
		label: 'All Products',
		options: [
			{ value: 'All Products', label: 'Bank Transfer' },
			{ value: 'Bank Transfer', label: 'Bank Transfer' },
			{ value: 'Gift Card', label: 'Gift Card' },
			{ value: 'Mobile Top-up', label: 'Mobile Top-up' },
		],
	},
	{
		field: 'transactionId',
		label: 'Search by Tx ID',
		options: [
			{ value: '7', label: 'Last 7 days' },
			{ value: '15', label: 'Last 15 days' },
			{ value: '30', label: 'Last 30 days' },
			{ value: '90', label: 'Last 90 days' },
		],
	},
	{
		field: 'status',
		label: 'Status',
		options: [
			{ value: 'Pending', label: 'Pending' },
			{ value: 'Proccessed', label: 'Proccessed' },
			{ value: 'Failed', label: 'Failed' },
		],
	},
];

// This is deposit transaction for crypto and fiat+++++++
export default function PHelpCenter() {
	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		formState: { isSubmitSuccessful },
	} = useForm({});
	const { headers } = usePartners();

	const [attachments, setAttachments] = useState<any>(null);
	const [isAttachment, switchattchamnentToogle] = useState<boolean>(false);
	useEffect(() => {
		if (!isSubmitSuccessful) {
			return;
		} else if (isSubmitSuccessful) {
			reset({
				cc: '',
				subject: '',
				description: '',
				attachments: '',
			});
			switchattchamnentToogle(false);
			setAttachments(null);
			console.log(isAttachment);
		}
	}, [isSubmitSuccessful]);
	const onAttachmentChange = (e: any) => {
		const files = Array.from(e.target.files);
		setAttachments(files);
	};

	const onSubmit = async (data: any) => {
		try {
			console.log({ data });
			const formData = new FormData();
			formData.append('requestType', data?.requestType);
			if (data.cc) {
				formData.append('cc', data?.cc);
			}
			formData.append('subject', data.subject);
			formData.append('description', data.description);
			if (attachments) {
				if (Array.isArray(attachments)) {
					console.log({ attachments });
					for (let i = 0; i < attachments.length; i++) {
						formData.append('attachments', attachments[i]);
					}
				} else {
					formData.append('attachments', attachments);
				}
			}

			console.log({ formData });

			const response = await axios({
				method: 'POST',
				url: ROUTES.Partners_HELP_CENTER,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
					...headers,
				},
			});
			toast.success('Query successfully raised!');
			console.log({ response });
		} catch (e: any) {
			console.log(e);
			toast.error(e.message);
		}
	};
	console.log({ isAttachment });

	return (
		<PartnerScreen className={classes.container}>
			<div style={{ paddingLeft: '3%', paddingRight: '3%' }}>
				<div className={classes.header}>
					<Controller
						control={control}
						name="requestType"
						render={({ field }) => (
							<MenuDropDownFilter
								style={{ width: '50% !important' }}
								menuList={filterList[0].options.map(
									(option) => option.value,
								)}
								onItemSelect={(e) => {
									field.onChange(e.value);
								}}
							/>
						)}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						gap: '20px',
					}}
				>
					<div style={{ width: '40%', paddingTop: '25px' }}>
						<label style={{ color: 'white' }}>CC</label>
						<div style={{ width: '100%' }}>
							<InputField
								setValue={setValue}
								errors={errors}
								register={register}
								entry={{ name: 'cc', placeholder: 'cc' }}
							/>
						</div>
					</div>
					<div style={{ width: '60%' }}>
						<label style={{ color: 'white' }}>Subject</label>
						<InputField
							setValue={setValue}
							errors={errors}
							register={register}
							entry={{ name: 'subject', placeholder: 'Subject' }}
						/>
					</div>
					<div style={{ width: '60%', height: '100px' }}>
						<label style={{ color: 'white', fontSize: '1rem' }}>
							Description
						</label>
						<InputField
							setValue={setValue}
							errors={errors}
							register={register}
							entry={{
								name: 'description',
								placeholder: 'Description',
								type: 'textarea',
							}}
						/>
					</div>
					<div>
						<input
							type="checkbox"
							placeholder="isAttachment(optional)"
							id="attachment"
							onChange={(e: any) =>
								switchattchamnentToogle(e.target.checked)
							}
						/>
						<span
							style={{
								marginLeft: '20px ',
								color: 'white',
								fontSize: '1rem',
							}}
						>
							Attachments(optional)
						</span>
					</div>

					<div
						style={{
							display: 'flex',
							width: '100%',
							justifyContent: 'space-between',
						}}
					>
						<div>
							<input
								type="file"
								disabled={!isAttachment}
								multiple
								id="attachments"
								onChange={onAttachmentChange}
							/>
							{attachments && attachments?.length > 0 && (
								<div style={{ marginTop: '10px' }}>
									<ul>
										{attachments.map(
											(attachment: any, index: number | string) => (
												<li key={index}>{attachment.name}</li>
											),
										)}
									</ul>
								</div>
							)}
						</div>
						<div>
							<Button onClick={handleSubmit(onSubmit)}>Submit</Button>
						</div>
					</div>
				</div>
			</div>
		</PartnerScreen>
	);
}
