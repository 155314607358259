import React from 'react';
import classes from '../styles/SidePane.module.scss';
import { ReactComponent as home } from 'assets/home.svg';
import { ReactComponent as customer } from 'assets/customer.svg';
import { ReactComponent as business } from 'assets/business.svg';
import { ReactComponent as deposit } from 'assets/deposite.svg';
import { ReactComponent as Setting } from 'assets/settings2.svg';
import { ReactComponent as Chart } from 'assets/Chart.svg';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';

const partnerPages = [
	{ name: 'Dashboard', path: '/dashboard', icon: home },
	{ name: 'Users', path: '/users', icon: customer },
	{ name: 'Deposits', path: '/deposits', icon: deposit },
	{
		name: 'Payout Transactions',
		path: '/payout_transactions',
		icon: business,
	},
	// {
	//     name: 'Payin Transactions',
	//     path: '/partners/payin_transactions',
	//     icon: business,
	// },
	{
		name: 'Withdrawals',
		path: '/withdrawal',
		icon: business,
	},
	{
		name: 'Providers Config',
		path: '/provider_config',
		icon: business,
	},
	{
		name: 'Revenue Report',
		path: '/revenue_report',
		icon: Chart,
	},
	{
		name: 'Statement Acc',
		path: '/statement_account',
		icon: Chart,
	},
	{
		name: 'Admin Access',
		path: 'admin_access',
		icon: Setting,
	},
	/*  {
        name: 'Wallet Details',
        path: '/wallet_details',
        icon: business,
    },
    {
        name: 'Bank Details',
        path: '/bank_details',
        icon: business,
    }, */
	// {
	//     name: 'Bank Applications',
	//     path: '/partners/bank_applications',
	//     icon: bank,
	// },
	// { name: 'Permissions', path: '/partners/permissions', icon: permission },
	// { name: 'Settings', path: '/partners/settings', icon: settings },
];

export default function Psidepanel() {
	const {
		location: { pathname },
	} = useHistory();

	return (
		<div className={classes.container}>
			{true &&
				partnerPages.map(({ icon: Icon, name, path }) => (
					<NavLink to={path} key={name}>
						<div
							className={`${classes.item} ${
								pathname.includes(path) ? classes.active : ''
							}`}
						>
							<Icon />
							<p>{name}</p>
						</div>
					</NavLink>
				))}
		</div>
	);
}
