import classes from 'styles/AdminDetailedDashboard.module.scss';

import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { ROUTES } from 'config';
import { usePartners } from 'context/partners';

import Piechart from 'components/charts/PieChartComponent';
import DoughnutChart from 'components/charts/DoughnutChart';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';

// import logo from 'assets/logo.png';
import Arrowdown from 'assets/arrow-down-black.svg';

interface Report {
	bankwires: Array<{
		fromLastYear: Array<{
			revenue: number;
			totalVolume: number;
			_id: string;
		}>;
		fromThisYear: Array<{
			revenue: number;
			totalVolume: number;
			_id: string;
		}>;
		fromYesterday: Array<{
			revenue: number;
			totalVolume: number;
			_id: string;
		}>;
		today: Array<{ revenue: number; totalVolume: number; _id: string }>;
	}>;
	giftcards: Array<{
		fromLastYear: Array<{
			revenue: number;
			totalVolume: number;
			_id: string;
		}>;
		fromThisYear: Array<{
			revenue: number;
			totalVolume: number;
			_id: string;
		}>;
		fromYesterday: Array<{
			revenue: number;
			totalVolume: number;
			_id: string;
		}>;
		today: Array<{ revenue: number; totalVolume: number; _id: string }>;
	}>;
	mobile_topup: Array<{
		fromLastYear: Array<{
			revenue: number;
			totalVolume: number;
			_id: string;
		}>;
		fromThisYear: Array<{
			revenue: number;
			totalVolume: number;
			_id: string;
		}>;
		fromYesterday: Array<{
			revenue: number;
			totalVolume: number;
			_id: string;
		}>;
		today: Array<{ revenue: number; totalVolume: number; _id: string }>;
	}>;
	usersOnboarding: Array<{
		fromLastYear: Array<{ fromLastYearCount: number; _id: string }>;
		fromThisYear: Array<{ currentYearCount: number; _id: string }>;
		fromYesterday: Array<{ yesterdayCount: number; _id: string }>;
		today: Array<{ todayCount: number; _id: string }>;
	}>;
	usersTraded: Array<{
		fromLastYear: number;
		fromThisYear: number;
		fromYesterday: number;
		today: number;
	}>;
}

export default function PdetailedDashboard() {
	const { Ptoken } = usePartners();

	const [userReport, setUserReport] = useState<any>({});
	const [pieData, setPieData] = useState<any>([]);
	const [loading, setLoading] = useState(true);
	const [doughnutData, setDoughnutData] = useState<any>([]);
	const [tableRows, setTableRows] = useState(new Array(4).fill(0));
	const [showDropdown, setShowDropDown] = useState(false);
	const [currentVal, setCurrentVal] = useState('Current Year');

	const tableColumnTopLabel = [
		'Today',
		'Yesterday',
		'Year To Date',
		'Previous YTD',
	];
	const tableColumnRowLabel = [
		'Users Onboarded',
		'Users Traded',
		'Volume',
		'Revenue',
	];

	const calculatePiechartData = useCallback(
		(toggler: any) => {
			const clasification = ['bankwires', 'giftcards', 'mobile_topup'];

			const pieChartData = clasification.map((c, index) => {
				const name =
					c === clasification[0]
						? 'Bank Wire'
						: c === clasification[1]
						? 'Gift Cards'
						: 'Mobile Topup';
				const value =
					parseFloat(
						(userReport as any)[c]?.[0]?.[toggler][0]?.revenue.toPrecision(
							2,
						),
					) || parseFloat('0');
				return {
					name,
					value,
				};
			});

			setPieData(pieChartData);
		},
		[userReport],
	);

	const calculateDoughNutData = useCallback(
		(toggler: string) => {
			const clasification = ['bankwires', 'giftcards', 'mobile_topup'];

			const doughnutData = clasification.map((c, index) => {
				const name =
					c === clasification[0]
						? 'Bank Wire'
						: c === clasification[1]
						? 'Gift Cards'
						: 'Mobile Topup';

				const value =
					parseFloat(
						(userReport as any)[c]?.[0]?.[
							toggler
						][0]?.totalVolume.toPrecision(2),
					) || parseFloat('0');
				return {
					name,
					value,
				};
			});

			setDoughnutData(doughnutData);
		},
		[userReport],
	);

	const fetchReport = async () => {
		try {
			const response = await axios({
				method: 'GET',
				baseURL: ROUTES.report_dashboard,
				headers: {
					Authorization: `Bearer ${Ptoken}`,
				},
			});

			const data: Report = response.data.data;

			setUserReport(data);

			const usersOnboarding = {
				fromLastYear:
					data.usersOnboarding[0]?.fromLastYear[0]?.fromLastYearCount || 0,
				fromThisYear:
					data.usersOnboarding[0]?.fromThisYear[0]?.currentYearCount || 0,
				fromYesterday:
					data.usersOnboarding[0]?.fromYesterday[0]?.yesterdayCount || 0,
				today: data.usersOnboarding[0]?.today[0]?.todayCount || 0,
			};
			const volume = {
				fromLastYear:
					(data.bankwires[0]?.fromLastYear[0]?.totalVolume || 0) +
					(data.giftcards[0]?.fromLastYear[0]?.totalVolume || 0) +
					(data.mobile_topup[0]?.fromLastYear[0]?.totalVolume || 0),
				fromThisYear:
					(data.bankwires[0]?.fromThisYear[0]?.totalVolume || 0) +
					(data.giftcards[0]?.fromThisYear[0]?.totalVolume || 0) +
					(data.mobile_topup[0]?.fromThisYear[0]?.totalVolume || 0),
				fromYesterday:
					(data.bankwires[0]?.fromYesterday[0]?.totalVolume || 0) +
					(data.giftcards[0]?.fromYesterday[0]?.totalVolume || 0) +
					(data.mobile_topup[0]?.fromYesterday[0]?.totalVolume || 0),
				today:
					(data.bankwires[0]?.today[0]?.totalVolume || 0) +
					(data.giftcards[0]?.today[0]?.totalVolume || 0) +
					(data.mobile_topup[0]?.today[0]?.totalVolume || 0),
			};
			const revenue = {
				fromLastYear:
					(data.bankwires[0]?.fromLastYear[0]?.revenue || 0) +
					(data.giftcards[0]?.fromLastYear[0]?.revenue || 0) +
					(data.mobile_topup[0]?.fromLastYear[0]?.revenue || 0),
				fromThisYear:
					(data.bankwires[0]?.fromThisYear[0]?.revenue || 0) +
					(data.giftcards[0]?.fromThisYear[0]?.revenue || 0) +
					(data.mobile_topup[0]?.fromThisYear[0]?.revenue || 0),
				fromYesterday:
					(data.bankwires[0]?.fromYesterday[0]?.revenue || 0) +
					(data.giftcards[0]?.fromYesterday[0]?.revenue || 0) +
					(data.mobile_topup[0]?.fromYesterday[0]?.revenue || 0),
				today:
					(data.bankwires[0]?.today[0]?.revenue || 0) +
					(data.giftcards[0]?.today[0]?.revenue || 0) +
					(data.mobile_topup[0]?.today[0]?.revenue || 0),
			};

			const filteredData = {
				usersOnboarding: usersOnboarding,
				usersTraded: data.usersTraded,
				volume,
				revenue,
			};

			const finalTableRows = tableColumnRowLabel.map((label, index) => {
				const key = Object.keys(filteredData)[index] as
					| 'usersOnboarding'
					| 'usersTraded'
					| 'volume'
					| 'revenue';
				const { fromLastYear, fromThisYear, fromYesterday, today } =
					filteredData[key] as {
						fromLastYear: number;
						fromThisYear: number;
						fromYesterday: number;
						today: number;
					};
				console.log({ fromLastYear, fromThisYear, fromYesterday, today });
				return {
					category: label,
					today: parseInt(today.toPrecision()).toFixed(2),
					yesterday: parseInt(fromYesterday.toPrecision()).toFixed(2),
					fromThisYear: parseInt(fromThisYear.toPrecision()).toFixed(2),
					fromLastYear: parseInt(fromLastYear.toPrecision()).toFixed(2),
				};
			});

			setTableRows(finalTableRows);

			setLoading(false);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		fetchReport();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	console.log({ pieData, doughnutData });

	useEffect(() => {
		calculatePiechartData('fromThisYear');

		calculateDoughNutData('fromThisYear');
	}, [calculateDoughNutData, calculatePiechartData, userReport]);

	if (loading) {
		return (
			<div
				className={classes.container}
				style={{ color: 'white', alignItems: 'center' }}
			>
				Loading...
			</div>
		);
	}

	return (
		<div
			className={classes.container}
			style={{
				color: 'white',
				// height: '68vh',
			}}
		>
			{/* <div style={{ marginTop: '40px' }}> </div> */}
			<div
				style={{
					padding: '0px 40px',
					border: '1px solid #DBB42D',
					borderRadius: '10px',
					filter: 'drop-shadow(0px 5px 9px rgba(165, 159, 159, 0.22))',
					height: 'auto',
				}}
			>
				<h1>Users Overview</h1>

				<table
					cellSpacing={'16px'}
					width={'100%'}
					style={{ textAlign: 'center' }}
				>
					<thead>
						<tr>
							<th></th>
							{tableColumnTopLabel.map((label, index) => (
								<th scope="col" key={index}>
									{label}
								</th>
							))}
						</tr>
					</thead>

					<tbody>
						{tableRows.map((row, index) => (
							<tr key={index}>
								<th scope="row">{row.category}</th>
								<td>{row.today}</td>
								<td>{row.yesterday}</td>
								<td>{row.fromThisYear}</td>
								<td>{row.fromLastYear}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<div
				style={{
					marginTop: '20px',
					padding: '20px 40px',
					border: '1px solid #DBB42D',
					borderRadius: '10px',
					filter: 'drop-shadow(0px 5px 9px rgba(165, 159, 159, 0.22))',
					height: 'auto',
				}}
			>
				<h1>Product Overview</h1>

				<div style={{ display: 'flex' }}>
					<div>
						<p>Volume by product type</p>
						<DoughnutChart data={doughnutData} />
					</div>
					<div>
						<p>Revenue by product type</p>
						<Piechart data={pieData} />
					</div>
					<div>
						<Button
							style={{ position: 'relative' }}
							onClick={() => setShowDropDown(!showDropdown)}
						>
							<p style={{ cursor: 'pointer' }}>{currentVal}</p>
							<img src={Arrowdown} alt="arrow-img" />
							{showDropdown && (
								<div
									style={{
										position: 'absolute',
										top: '100%',
										right: '-50%',
									}}
								>
									<Dropdown handleClick={() => setShowDropDown(false)}>
										<p
											onClick={() => {
												setCurrentVal('Monthly');
												calculatePiechartData('fromThisMonth');
												calculateDoughNutData('fromThisMonth');
											}}
										>
											Monthly
										</p>
										<p
											onClick={() => {
												setCurrentVal('Current Year');
												calculatePiechartData('fromThisYear');
												calculateDoughNutData('fromThisYear');
											}}
										>
											Current Year
										</p>
										<p
											onClick={() => {
												setCurrentVal('Past Year');
												calculatePiechartData('fromLastYear');
												calculateDoughNutData('fromLastYear');
											}}
										>
											Past Year
										</p>
									</Dropdown>
								</div>
							)}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}
