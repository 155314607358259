import React, { useRef, useState, useEffect } from 'react';
import classes from 'styles/AdminHeader.module.scss';
import logo from '../assets/logo.png';
import useOutsideClick from '../hooks/useOutsideClick';
import { Link, useHistory } from 'react-router-dom';
import Dropdown from './Dropdown';
import Home from 'assets/Navbar svg/Home';
import Wallet from 'assets/Navbar svg/Wallet';
import Bank from 'assets/Navbar svg/Bank';
import Chat from 'assets/Navbar svg/Chat';
import Setting from 'assets/Navbar svg/Setting';
import Arrowdown from 'assets/Arrow - Down 4.png';
import { useNotification } from 'context/notification';
import ReactTooltip from 'react-tooltip';
import { usePartners } from 'context/partners';

export default function PartnersHeader() {
	const [showDropdown, setShowDropDown] = useState(false);
	const dropRef = useRef(null);
	const { logout, Partner } = usePartners();
	const { notifications } = useNotification();
	const [isNotificationsOpen, setIsNotificationsOpen] =
		useState<boolean>(false);
	// eslint-disable-next-line
	const [unreadNotifications, setUnreadNotifications] = useState(0);
	const notificationRef = useRef();
	const { push } = useHistory();
	// console.log(Partner);

	// useEffect(() => {
	//     const checkIfClickedOutsideNotificationMenu = (e: { target: any }) => {
	//         if (
	//             isNotificationsOpen &&
	//             notificationRef.current &&
	//             !(notificationRef.current as any).contains(e.target)
	//         ) {
	//             setIsNotificationsOpen(false);
	//         }
	//     };
	//     document.addEventListener(
	//         'mousedown',
	//         checkIfClickedOutsideNotificationMenu,
	//     );
	//     return () => {
	//         document.removeEventListener(
	//             'mousedown',
	//             checkIfClickedOutsideNotificationMenu,
	//         );
	//     };
	// }, [isNotificationsOpen]);

	// useEffect(() => {
	//     setUnreadNotifications(notifications.length);
	// }, [notifications]);

	// const togglingNotifications = () => {
	//   setIsNotificationsOpen(!isNotificationsOpen);
	//   setUnreadNotifications(0);
	// };

	useOutsideClick(dropRef, () => {
		setShowDropDown(false);
	});

	return (
		<header className={classes.container}>
			<img
				src={logo}
				alt="ENCRYPTUS LOGO"
				className={classes.left}
				onClick={() => push('/dashboard')}
			/>
			{/* {Partner && <span className={classes.adminType}>Partners Dashboard</span>} */}
			<div ref={dropRef} className={classes.right}>
				{/* navigation links++++++++++++++++++++++ */}
				<div className={classes.navigation}>
					<Link
						to="dashboard"
						className={classes.icons}
						data-tip
						data-for="registerTip1"
					>
						<Home />
						<ReactTooltip
							id="registerTip1"
							place="bottom"
							effect="float"
							textColor="#DBB42C"
							backgroundColor="#173486"
						>
							Home
						</ReactTooltip>
					</Link>
					<Link
						to="bank_details"
						className={classes.icons}
						data-tip
						data-for="registerTip2"
					>
						<Bank />
						<ReactTooltip
							id="registerTip2"
							place="bottom"
							effect="float"
							textColor="#DBB42C"
							backgroundColor="#173486"
						>
							Bank Details
						</ReactTooltip>
					</Link>
					<Link
						to="wallet_details"
						className={classes.icons}
						data-tip
						data-for="registerTip3"
					>
						<Wallet />
						<ReactTooltip
							id="registerTip3"
							place="bottom"
							effect="float"
							textColor="#DBB42C"
							backgroundColor="#173486"
						>
							Wallet Details
						</ReactTooltip>
					</Link>
					<Link
						to="help_center"
						className={classes.icons}
						data-tip
						data-for="registerTip4"
					>
						<Chat />
						<ReactTooltip
							id="registerTip4"
							place="bottom"
							effect="float"
							textColor="#DBB42C"
							backgroundColor="#173486"
						>
							Help Center
						</ReactTooltip>
					</Link>
					<Link
						to="help_center"
						className={classes.icons}
						data-tip
						data-for="registerTip5"
					>
						<Setting />
						<ReactTooltip
							id="registerTip5"
							place="bottom"
							effect="float"
							textColor="#DBB42C"
							backgroundColor="#173486"
						>
							Settings
						</ReactTooltip>
					</Link>
					{/* <div
            className={classes.icons}
            ref={notificationRef as any}
            data-tip
            data-for="registerTip4"
          >
            <div
              className={classes.notificationIcon}
              onClick={togglingNotifications}
            >
              <NotificationBell />
              <ReactTooltip
                id="registerTip4"
                place="bottom"
                effect="float"
                textColor="#DBB42C"
                backgroundColor="#173486"
              >
                Notifications
              </ReactTooltip>
            </div>
            {unreadNotifications > 0 && (
              <div className={classes.newNotifications}>
                {unreadNotifications}
              </div>
            )}
            <NotificationsDropdown
              reference={notificationRef}
              isOpen={isNotificationsOpen}
              close={() => setIsNotificationsOpen(false)}
              data={notifications.reverse()}
            />
          </div> */}
				</div>
				<div
					className={classes.details}
					onClick={() => setShowDropDown((p) => !p)}
				>
					<span className={classes.initials}>JP</span>
					<p>
						{(Partner && Partner.clientRepresentiveEmail.slice(0, 20)) ||
							''}
					</p>
					<img src={Arrowdown} alt="arrow-img" />
				</div>
				{/* Dropdown ++++++++++++++++++++++++++++ */}
				{showDropdown && (
					<Dropdown handleClick={() => setShowDropDown(false)}>
						<Link to="bank_details" className="stepOne">
							<p>Bank Deatils</p>
						</Link>
						<hr />
						<p onClick={() => logout()}>Logout</p>
					</Dropdown>
				)}
			</div>
		</header>
	);
}
