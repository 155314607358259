import AdminScreen from 'components/adminScreen';
import React, { useEffect, useState } from 'react';
import classes from 'styles/ProviderConfig.module.scss';
import { toast } from 'react-toastify';
import { useModal } from 'context/modal';
import { ROUTES } from 'config';
import Button from 'components/Button';
import Filters from 'components/Filters';
import useFilters from 'hooks/useFilters';
import Paginate from 'components/Paginate';
import usePartnersPaginate from 'hooks/usePartnersPaginate';
import edit from '../assets/edit.svg';
import axios from 'axios';
import { useAdmin } from 'context/admin';
export default function ProviderConfig() {
	const { Admin, Atoken, headers } = useAdmin();

	// const [collections, { reload, paginateProps }] =
	// 	usePartnersPaginate<any>(
	// 		{
	// 			route: ROUTES.Admin_Providers,
	// 			filterData: (data) => {
	// 				console.log({ ddd: data });
	// 				return data.data;
	// 			},
	// 		},
	// 		[],
	// 	);
	// useEffect(() => {
	// 	reload();
	// }, []);
	const [Providers, setProviders] = useState<any[]>([]);
	const [AddNewProvider, setAddProvider] = useState<boolean>(false);
	const [Update, setUpdate] = useState<boolean>(false);
	const [Editrow, setEditRow] = useState<boolean>(false);
	const [editedRowId, setEditedRowId] = useState<string | null>(null);
	const fetchProviders = async () => {
		try {
			const response = await axios({
				method: 'GET',
				baseURL: ROUTES.Admin_Providers,
				headers: {
					Authorization: `Bearer ${Atoken}`,
				},
			});
			const data = response.data.data;
			setProviders(data);
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		fetchProviders();
	}, []);

	const UpdateProvider = async (data: any) => {
		try {
			console.log({ data });
			await axios({
				method: 'POST',
				url: ROUTES.Admin_Update_Provider_Config,
				data: data,
				headers: {
					'Content-Type': 'application/json',
					...headers,
				},
			});
			toast.success('Updated Configuration Successfully!');
		} catch (e: any) {
			console.log(e);
			toast.error(e.message);
		}
	};
	const handleUpdateRow = async (item: any) => {
		const limitLCInput = document.getElementById(
			`limitLC_${item._id}`,
		) as HTMLInputElement;
		const StdFeeInput = document.getElementById(
			`feePerTransactionUSD_standard_${item._id}`,
		) as HTMLInputElement;
		const ExpFeeInput = document.getElementById(
			`feePerTransactionUSD_express_${item._id}`,
		) as HTMLInputElement;

		const updatedData = {
			_id: item._id,
			limitLC: limitLCInput.value,
			feePerTransactionUSD_standard: StdFeeInput.value,
			feePerTransactionUSD_express: ExpFeeInput.value,
		};
		console.log(updatedData);
		await UpdateProvider(updatedData);
		fetchProviders();
	};

	const setNewProvider = async (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();
		const productInput = document.getElementById(
			'productName',
		) as HTMLInputElement;
		const providerInput = document.getElementById(
			'providerName',
		) as HTMLInputElement;
		const transferInput = document.getElementById(
			'transferType',
		) as HTMLInputElement;
		const countrycodeInput = document.getElementById(
			'countryCode',
		) as HTMLInputElement;
		const countrynameInput = document.getElementById(
			'countryName',
		) as HTMLInputElement;
		const currencyInput = document.getElementById(
			'currency',
		) as HTMLInputElement;
		const limitLCInput = document.getElementById(
			'limitLC',
		) as HTMLInputElement;
		const StdFeeInput = document.getElementById(
			'feePerTransactionUSD_standard',
		) as HTMLInputElement;
		const ExpFeeInput = document.getElementById(
			'feePerTransactionUSD_express',
		) as HTMLInputElement;
		const data = {
			productName: productInput.value,
			providerName: providerInput.value,
			transferType: transferInput.value,
			countryCode: countrycodeInput.value,
			countryName: countrynameInput.value,
			currency: currencyInput.value,
			limitLC: limitLCInput.value,
			feePerTransactionUSD_standard: StdFeeInput.value,
			feePerTransactionUSD_express: ExpFeeInput.value,
		};
		console.log(data);
		await AddProvider(data);
		fetchProviders();
		setAddProvider(false);
	};
	const AddProvider = async (data: any) => {
		try {
			console.log({ data });
			await axios({
				method: 'POST',
				url: ROUTES.Admin_Add_Provider_Config,
				data: data,
				headers: {
					'Content-Type': 'application/json',
					...headers,
				},
			});
			toast.success('Added a new provider configuration successfully ! ');
			setEditedRowId(null);
		} catch (e: any) {
			console.log(e);
			toast.error(e.message);
		}
	};
	return (
		<AdminScreen className={classes.container}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-around',
					paddingBottom: '20px',
					paddingTop: '20px',
				}}
			>
				<Button onClick={() => setUpdate(true)}>Update Provider</Button>
				<h1 style={{ fontSize: '45px' }}>Providers</h1>
				<Button onClick={() => setAddProvider(true)}>
					Add New Provider
				</Button>
			</div>
			<div className={classes.providers}>
				<table>
					<thead>
						<tr>
							{Update && <th> </th>}
							<th>Product Name</th>
							<th>Provider Name</th>
							<th>Transfer Type</th>
							<th>Country Code</th>
							<th>Country Name</th>
							<th>Currency</th>
							<th>Limit LC</th>
							<th>Standard Fee USD</th>
							<th>Express Fee USD</th>
						</tr>
					</thead>
					{AddNewProvider && (
						<tbody>
							<tr>
								<td>
									<input type="text" id="productName" required></input>
								</td>
								<td>
									<input type="text" id="providerName" required />
								</td>
								<td>
									<input type="text" id="transferType" required />
								</td>
								<td>
									<input type="text" id="countryCode" required />
								</td>
								<td>
									<input type="text" id="countryName" required />
								</td>
								<td>
									<input type="text" id="currency" required />
								</td>
								<td>
									<input type="number" id="limitLC" required />
								</td>
								<td>
									<input
										type="number"
										id="feePerTransactionUSD_standard"
										required
									/>
								</td>
								<td>
									<input
										type="number"
										id="feePerTransactionUSD_express"
										required
									/>
								</td>
								<td>
									<button onClick={setNewProvider}> Add</button>
								</td>
								<td>
									<button
										className={classes.cancelbtn}
										onClick={() => setAddProvider(false)}
									>
										✖
									</button>
								</td>
							</tr>
						</tbody>
					)}
					{Providers.length > 0 ? (
						<tbody>
							{Providers.map((item: any) => (
								<tr key={item._id}>
									{Update && (
										<td>
											<button onClick={() => setEditedRowId(item._id)}>
												edit
											</button>
										</td>
									)}
									<td>{item.productName}</td>
									<td>{item.providerName}</td>
									<td>{item.transferType}</td>
									<td>{item.countryCode}</td>
									<td>{item.countryName}</td>
									<td>{item.currency}</td>
									{editedRowId === item._id ? (
										<>
											<td>
												<input
													type="number"
													defaultValue={item.limitLC}
													id={`limitLC_${item._id}`}
												/>
											</td>
											<td>
												<input
													type="number"
													defaultValue={item.feePerTransactionUSD_standard}
													id={`feePerTransactionUSD_standard_${item._id}`}
												/>
											</td>
											<td>
												<input
													type="number"
													defaultValue={item.feePerTransactionUSD_express}
													id={`feePerTransactionUSD_express_${item._id}`}
												/>
											</td>
											<td>
												<button onClick={() => handleUpdateRow(item)}>
													Save
												</button>
											</td>
											<td>
												<button
													onClick={() => {
														setUpdate(false);
														setEditedRowId(null);
													}}
												>
													✖
												</button>
											</td>
										</>
									) : (
										<>
											<td>{item.limitLC}</td>
											<td>{item.feePerTransactionUSD_standard}</td>
											<td>{item.feePerTransactionUSD_express}</td>
										</>
									)}
								</tr>
							))}
						</tbody>
					) : (
						<tbody>
							<tr>
								<td colSpan={10}>Loading...</td>
							</tr>
						</tbody>
					)}
				</table>
			</div>
			{/* <Paginate {...paginateProps} /> */}
		</AdminScreen>
	);
}
