import React, { useState, useEffect } from 'react';
import classes from '../styles/AdminScreen.module.scss';
import { HTMLELEMENT } from '../interfaces';
import Backdrop from 'assets/backdrop.png';
import { useLocation } from 'react-router-dom';
import { usePartners } from 'context/partners';
import Psidepanel from './PSidepanel';
import axios from 'axios';
import { ROUTES } from 'config';
import currencyFormatter from 'currency-formatter';
interface Props extends HTMLELEMENT<HTMLDivElement> { }

const Server_Host = process.env.REACT_APP_AWS_SERVER_HOST;
const API = `${Server_Host}/tickets/crypto/balance`;

export default function PartnerScreen({
	children,
	className,
	...rest
}: Props) {
	const { Ptoken } = usePartners();
	const [usdtBalance, setUsdtBalance] = useState<number | string>();
	const [usdcBalance, setUsdcBalance] = useState<number | string>();

	const fetchBalance = async () => {
		try {
			const response = await axios({
				method: 'GET',
				baseURL: ROUTES.Partners_CRYPTO_BALANCE_TICKETS,
				headers: {
					Authorization: `Bearer ${Ptoken}`,
				},
			});
			const cryptoData = response.data.data.info[0].crypto_bal;

			const usdtCrypto = cryptoData.find(
				(item: any) => item.crypto_curr === 'USDT',
			);
			const usdcCrypto = cryptoData.find(
				(item: any) => item.crypto_curr === 'USDC',
			);

			if (usdtCrypto) {
				const balance = usdtCrypto.crypto_coins;
				setUsdtBalance(Number.parseFloat(balance).toFixed(2));
			} else {
				setUsdtBalance('USDT Balance not found.');
			}

			if (usdcCrypto) {
				const balance = usdcCrypto.crypto_coins;
				setUsdcBalance(Number.parseFloat(balance).toFixed(2));
			} else {
				setUsdcBalance('USDC Balance not found.');
			}
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		fetchBalance();
	}, []);
	const location = useLocation();
	const path = location.pathname;
	// eslint-disable-next-line
	const [main1, main] = path.split('/');

	// ? Method to render the header for the page
	const renderHeader = () => {
		switch (main) {
			case 'users':
				return 'Users';

			case 'deposits':
				return 'Deposits';

			case 'payin_transactions':
				return 'Payout Transactions';

			case 'payout_transactions':
				return 'Payout Transactions';

			case 'withdrawal':
				return 'Withdrawals';

			case 'admin_withdrawal':
				return 'Withdrawals';

			case 'admin_deposits':
				return 'Deposits';

			case 'wallet_details':
				return 'Wallet Details';

			case 'bank_details':
				return 'Bank Details';

			case 'permissions':
				return 'Permission';

			case 'settings':
				return 'Settings';

			case 'revenue_report':
				return 'Revenue Report';

			case 'statement_account':
				return 'Statement Account';
			case 'admin_access':
				return 'Admin Access';
			case 'help_center':
				return 'Help Center';
			case 'provider_config':
				return 'Providers Configuration';
			default:
				return main;
		}
	};
	// * End of renderHeader()


	return (
		<div className={classes.container}>
			<div className={classes.header_div}>
				<h1 className={classes.header_text}>{renderHeader()}</h1>
				<div className={classes.current_balance_container}>
					Current Balance
					<p className={classes.current_balance}>
						{typeof usdtBalance == 'number' ? `${currencyFormatter.format(usdtBalance, { code: 'USD' })} USDT` : usdtBalance} USDT
					</p>
					<p className={classes.current_balance}>
						{typeof usdcBalance == 'number' ? `${currencyFormatter.format(usdcBalance, { code: 'USD' })} USDC` : usdcBalance} USDC
					</p>
				</div>
			</div>
			<section className={classes.innerContainer}>
				<div className={classes.sidepanel}>
					<Psidepanel />
				</div>
				<div {...rest} className={`${classes.child} ${className || ''}`}>
					{children}
				</div>
			</section>
			<img
				src={Backdrop}
				alt="backdrop"
				className={classes.backdrop}
			></img>
		</div>
	);
}
