import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ModalProvider } from './context/modal';
import { BrowserRouter as Router } from 'react-router-dom';
import { SearchProvider } from './context/search';
import { PartnersProvider } from 'context/partners';
import { AdminProvider } from 'context/admin';

ReactDOM.render(
	<React.StrictMode>
		<SearchProvider>
			<Router>
				<PartnersProvider>
					<AdminProvider>
						<ModalProvider>
							<App />
						</ModalProvider>
					</AdminProvider>
				</PartnersProvider>
			</Router>
		</SearchProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);
