
import React from 'react';

import classes from "../../styles/AdminHeader.module.scss";

function Chat() {
  return <div>
    <svg className={ classes.headerIcon } width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M24.6338 25.4265C20.6862 29.5018 14.8409 30.3823 10.0574 28.0987C9.35121 27.8052 8.77225 27.568 8.22186 27.568C6.6888 27.5774 4.78059 29.1118 3.78884 28.0893C2.79709 27.0654 4.28472 25.0941 4.28472 23.5021C4.28472 22.9338 4.06404 22.3469 3.77975 21.6165C1.56649 16.6795 2.42064 10.6436 6.36817 6.5696C11.4074 1.3659 19.5945 1.3659 24.6338 6.56826C29.6821 11.78 29.673 20.2241 24.6338 25.4265Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.5883 16.5507H20.5999" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.41 16.5507H15.4217" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.2318 16.5507H10.2434" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>;
}

export default Chat;
