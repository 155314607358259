import classes1 from 'styles/InputPhoneNumber.module.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const ReactPhoneInput = (props: any) => {
    return (
        <div className={classes1.phoneContainer}>
            <PhoneInput
                country={props.country}
                placeholder="Enter phone number"
                onChange={(value, country, event) =>
                    props.setValue('phone_no', event.target.value)
                }
                buttonStyle={{
                    border: 'none',
                    backgroundColor: 'transparent',
                }}
                inputProps={{
                    name: 'phone_no',
                    id: 'clean',
                    required: true,
                    autoFocus: false,
                }}
                containerStyle={{
                    width: '100%',
                    marginTop: '0.1rem',
                    marginLeft: '0.6rem',
                }}
                inputStyle={{
                    border: '1px solid #EAEFF5 ',
                    width: '95%',
                }}
                onlyCountries={[
                    'ae', // United Arab Emirates
                    'al', // Albania
                    'ad', // Andorra
                    'am', // Armenia
                    // 'ba', // Bosnia and Herzegovina
                    'fo', // Faroe Islands
                    'gi', // Gibraltar
                    'is', // Iceland
                    'xk', // Kosovo
                    'li', // Liechtenstein
                    // 'mk', // Republic of North Macedonia
                    'md', // Moldova
                    'mc', // Monaco
                    'me', // Montenegro
                    'no', // Norway
                    'sm', // San Marino
                    // 'rs', // Serbia
                    'ch', // Switzerland
                    // 'ua', // Ukraine
                    'gb', // United Kingdom
                    'va', // Vatican City
                    'kw', // Kuwait
                    'qa', // Qatar
                    'tr', // Turkey
                    'uk', // United Kingdom
                    'in', // India
                    'sa', // Saudi Arabia
                    'bh', // Bahrain
                    'at', // Austria
                    'be', // Belgium
                    'bg', // Bulgaria
                    'cy', // Cyprus
                    'cz', // Czech Republic
                    'de', // Germany
                    'dk', // Denmark
                    'ee', // Estonia
                    'es', // Spain
                    'fi', // Finland/Aland Islands
                    'fr', // France
                    'gr', // Greece
                    'hr', // Croatia
                    'hu', // Hungary
                    'ie', // Ireland
                    'it', // Italy
                    'lt', // Lithuania
                    'lu', // Luxembourg
                    'lv', // Latvia
                    'mt', // Malta
                    'nl', // Netherlands
                    'sg', // Singapore
                    'po', // Poland
                    'pt', // Portugal
                    'ro', // Romania
                    'se', // Sweden
                    'sk', // Slovakia
                    'si', // Slovenia
                    'bn', // Brunei
                    // 'kh', // Cambodia
                    // 'cn', // China
                    'ge', // Georgia
                    'hk', // Hong Kong
                    // 'id', // Indonesia
                    'jp', // Japan
                    'jo', // Jordan
                    // 'kg', // Kyrgyzstan
                    // 'la', // Laos
                    'mo', // Macau
                    'my', // Malaysia
                    'mv', // Maldives
                    'mn', // Mongolia
                    // 'mm', // Myanmar
                    'np', // Nepal
                    'om', // Oman
                    // 'ph', // Philippines
                    'lk', // Sri Lanka
                    'tw', // Taiwan
                    'th', // Thailand
                    // 'vn', // Vietnam

                    // ----- EUROPE -----
                    // 'by', // Belarus
                    'pl', // Poland

                    // ----- AFRICA -----
                    'cm', // Cameroon
                    'eg', // Egypt
                    'gh', // Ghana
                    'ke', // Kenya
                    // 'ng', // Nigeria
                    'tn', // Tunisia
                    'za', // South Africa
                    'zm', // Zambia
                ]}
            />
        </div>
    );
};

export default ReactPhoneInput;
