import PartnerScreen from 'components/partnersScreen';
import classes from 'styles/AdminDashboard.module.scss';
import PdetailedDashboard from './PdetailedDashboard';

export default function Pdashboard() {
	return (
		<PartnerScreen
			className={classes.container}
			style={{ height: 'auto' }}
		>
			<PdetailedDashboard />
		</PartnerScreen>
	);
}
