import React, { ReactNode, useEffect, useState } from 'react';
import classes from '../styles/Paginate.module.scss';

export interface PaginateProps {
	currentPage: number;
	lastPage: number;
	next: () => any;
	prev: () => any;
	jumpTo: (page: number) => any;
	itemsCount: number;
	prevPage: number;
}

export default function Paginate({
	lastPage,
	currentPage,
	jumpTo,
	prev,
	next,
	itemsCount,
	prevPage,
}: PaginateProps) {
	const [pageStart, setPageStart] = useState<number | any>();
	const [pageEnd, setPageEnd] = useState<number | any>();

	useEffect(() => {
		if (prevPage == 0) {
			console.log('if');
			setPageEnd(itemsCount);
			setPageStart(0);
		} else {
			console.log('else');
			setPageEnd(itemsCount + pageEnd);
			setPageStart(pageEnd);
		}
	}, [prevPage, currentPage]);

	if (!lastPage) return <></>;
	// const [startCount, setStartCount] = useState(1);
	// const [endCount, setEndCount] = useState(itemsCount);
	const arr = Array.apply(null, Array(lastPage));

	// console.log('items Count ' + itemsCount);
	// console.log('Last Page ' + lastPage);
	// console.log('prev Page ' + prevPage);
	// console.log('Current Page ' + currentPage);
	// console.log(`${pageStart} - ${pageEnd}`);
	return (
		<div className={classes.container}>
			<NumberCircle disabled={currentPage === 1} onClick={prev}>
				{'<'}
			</NumberCircle>
			{/* Display pages 1-10 (latest transactions) */}
			{/* {arr.slice(currentPage - 1, currentPage + 9).map((_, idx) => (
				<div key={idx.toString()}>
					<NumberCircle
						onClick={() => jumpTo(currentPage + idx)}
						active={currentPage === currentPage + idx}
					>
						{currentPage + idx}
					</NumberCircle>
				</div>
			))} */}
			<div
				style={{
					color: 'gold',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					fontSize: '20px',
				}}
			>
				Results: {pageStart + 1} - {pageEnd}
			</div>
			<NumberCircle disabled={currentPage === lastPage} onClick={next}>
				{'>'}
			</NumberCircle>
			{/* <NumberCircle onClick={oldest}>{'>>'}</NumberCircle> */}
		</div>
	);
}

interface NumberProps {
	active?: boolean;
	disabled?: boolean;
	onClick?: () => any;
	children: ReactNode;
}

function NumberCircle({
	active,
	disabled,
	onClick,
	children,
}: NumberProps) {
	return (
		<div
			className={`${classes.number} ${active ? classes.active : ''} ${
				disabled ? classes.disabled : ''
			}`}
			onClick={onClick}
		>
			<p>{children}</p>
		</div>
	);
}
