import { useCallback } from 'react';
import { ObjectSchema } from 'yup';

const useYupValidationResolver = (validationSchema?: ObjectSchema<any>) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema?.validate(data, {
                    abortEarly: false,
                });
                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? 'validation',
                                message: currentError.message,
                            },
                        }),
                        {},
                    ),
                };
            }
        },
        [validationSchema],
    );

export default useYupValidationResolver;
