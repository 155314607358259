import { useEffect } from 'react';

const useOutsideClick = (ref: any, callback: (event: MouseEvent) => void) => {
    useEffect(() => {
        // Function for click event
        function handleOutsideClick(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback(event);
            }
        }

        // Adding click event listener
        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
        // eslint-disable-next-line
    }, [ref]);
};

export default useOutsideClick;
