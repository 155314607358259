import { AdminHomePage } from '../components/AdminHomePage';

function AdminLoginScreen() {
	return (
		<main>
			<AdminHomePage />
		</main>
	);
}

export default AdminLoginScreen;
