import axios from 'axios';
import { usePartners } from 'context/partners';
// import { useUser } from 'context/user';
import { useEffect, useState } from 'react';
import { PaginateProps } from '../components/Paginate';

interface Props {
	route: string;
	params?: any;
	infinite?: boolean;
	filterData?: (data: any) => any;
}

interface Options {
	reload: () => Promise<void>;
	loadMore: () => Promise<void>;
	loadPrev: () => Promise<void>;
	loadPage: () => Promise<void>;
	page: number;
	hasMore: boolean;
	total: number;
	lastPage: number;
	loading: boolean;
	paginateProps: PaginateProps;
}

interface Fetch {
	page?: number;
	append?: boolean;
}

interface Fetched<T> {
	status: number;
	data: {
		info: T[];
		Message: number;
		total: number;
		hasMore: boolean;
	};
}

export default function usePartnersPaginate<T>(
	{ route, params = {}, infinite, filterData }: Props,
	deps: any[] = [],
	cb: (list: T[]) => T[] = (list) => list,
) {
	const { headers } = usePartners();
	const [list, setList] = useState<T[]>([]);
	// eslint-disable-next-line
	const [page, setPage] = useState(0);
	// eslint-disable-next-line
	const [hasMore, setHasMore] = useState(true);
	// eslint-disable-next-line
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);

	const getList = async ({
		page: fetchPage = page + 1,
		append = false,
	}: Fetch = {}) => {
		if (loading) return;
		setLoading(true);
		try {
			let updatedFilter: any = {};
			// eslint-disable-next-line
			Object.keys(params.filters).map((filter) => {
				if (params.filters[filter]) {
					updatedFilter[filter] = params.filters[filter];
				}
			});
			params.filters = updatedFilter;
			if (JSON.stringify(params.filters) === '{}') delete params.filters;
			const { data }: { data: Fetched<T> | any } = await axios.get(route, {
				params: {
					sort: { createdAt: -1 },
					page: fetchPage,
					...params,
				},
				headers,
			});
			// console.log(data, route);
			const l = cb(filterData ? filterData(data) : data.data.info);
			setList(l);
			setPage(parseInt(data.thisPage));
			setHasMore(data.hasMore);
			setTotal(data.total);
		} catch (error) {
			console.log({ error });
		} finally {
			setLoading(false);
		}
	};

	const loadMore = () =>
		hasMore ? getList({ page: page + 1, append: true }) : undefined;
	const reload = () => getList({ page: 1 });
	const loadPrev =
		page === 1 ? undefined : () => getList({ page: page - 1 });
	const loadPage = (page: number) => getList({ page });
	const lastPage = Math.ceil(total / 10);

	useEffect(() => {
		getList({ page: 1 });
		// eslint-disable-next-line
	}, [headers, ...deps]);

	return [
		list,
		{
			reload,
			loadMore,
			loadPrev,
			loadPage,
			page,
			hasMore,
			total,
			lastPage,
			loading,
			paginateProps: {
				itemsCount: list?.length,
				currentPage: page,
				prevPage: page - 1,
				lastPage,
				next: loadMore,
				prev: loadPrev,
				jumpTo: loadPage,
			},
		},
	] as [T[], Options];
}
