import React from 'react';
import Loader from 'react-loader-spinner';
import { COLORS, FilterListItem, HTMLELEMENT } from '../interfaces';
import classes from '../styles/Table.module.scss';
import Filters from './Filters';

interface Props extends HTMLELEMENT<HTMLTableElement> {
    loading?: boolean;
    title?: string;
    filterList?: FilterListItem[];
    filters?: object;
    updateFilters?: (key: string, value: string) => void;
    height?: string;
}

export default function Table({
    filterList,
    title,
    className,
    loading,
    filters,
    height,
    updateFilters,
    ...props
}: Props) {
    return (
        <div className={classes.container} style={{ height }}>
            <div className={classes.head}>
                <h2>{title}</h2>
                {filterList && (
                    <Filters
                        filters={filters}
                        updateFilters={updateFilters}
                        filterList={filterList}
                    />
                )}
            </div>
            {loading ? (
                <div className={classes.loading}>
                    <Loader type="ThreeDots" color={COLORS.PRIMARY} height={20} />
                </div>
            ) : (
                <table {...props} className={[classes.table, className].join(' ')} />
            )}
        </div>
    );
}
