import { NewHomePage } from '../components/NewHomePage';

function PartnersLoginScreen() {
    return (
        <main>
            <NewHomePage />
        </main>
    );
}

export default PartnersLoginScreen;
