import PartnerScreen from 'components/partnersScreen';
import { useEffect, useState } from 'react';
import classes from 'styles/Transaction.module.scss';
import { useModal } from 'context/modal';
import { ROUTES } from 'config';
// import CryptoDepositDetails from 'modals/CryptoDepositDetails';
// import DepositeTicket from 'modals/DepositeTicket';
import Button from 'components/Button';
import Filters from 'components/Filters';
import { FilterListItem } from 'interfaces';
import useFilters from 'hooks/useFilters';
import { useSearch } from 'context/search';
import Paginate from 'components/Paginate';
import { DATE_CONV } from 'utils/DateConv';
// import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { copyText } from 'utils/CopyContent';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import arrowDownBlack from 'assets/arrow-down-black.svg';
import Arrowdown from 'assets/Arrow - Down 4.png';
// import { usePartners } from 'context/partners';
import usePartnersPaginate from 'hooks/usePartnersPaginate';
import exportFromJSON from 'export-from-json';
import MobileToupsDetails from 'modals/MobileToupsDetails';
import { MenuDropDownFilter } from 'components/MenuDropDown';
import { Console } from 'console';
import { flattenObject } from 'utils';

const filterList: FilterListItem[] = [
	{
		field: 'date',
		label: 'Date',
		options: [
			{ value: 'Date', label: 'all' },
			{ value: '7', label: 'Last 7 days' },
			{ value: '15', label: 'Last 15 days' },
			{ value: '30', label: 'Last 30 days' },
			{ value: '90', label: 'Last 90 days' },
		],
	},
	{
		field: 'product',
		label: 'All Products',
		options: [
			{ value: 'All Products', label: 'Bank Transfer' },
			{ value: 'Bank Transfer', label: 'Bank Transfer' },
			{ value: 'Gift Card', label: 'Gift Card' },
			{ value: 'Mobile Wallet', label: 'Mobile Wallet' },
			{ value: 'Mobile Top-up', label: 'Mobile Top-up' },
		],
	},
	{
		field: 'transactionId',
		label: 'Search by Tx ID',
		options: [
			{ value: 'All', label: 'All' },
			{ value: '7', label: 'Last 7 days' },
			{ value: '15', label: 'Last 15 days' },
			{ value: '30', label: 'Last 30 days' },
			{ value: '90', label: 'Last 90 days' },
		],
	},
	{
		field: 'status',
		label: 'Status',
		options: [
			{ value: 'All', label: 'All' },
			{ value: 'Pending', label: 'Pending' },
			{ value: 'Proccessed', label: 'Proccessed' },
			{ value: 'Failed', label: 'Failed' },
		],
	},
];

// This is deposit transaction for crypto and fiat+++++++
export default function PpayoutTransaction() {
	const { openModal } = useModal();
	// const { headers } = usePartners();
	// States?
	const [Change, setChange] = useState('giftcard');
	const { search } = useSearch();
	const { filters, updateFilters, removeFilters } = useFilters();
	// eslint-disable-next-line
	const [status, setStatus] = useState('all');

	// ? Search Bar Values

	const [cryptoDepositSearchField, setCryptoDepositSearchField] =
		useState<string>('transaction_hash');
	const [cryptoDepositSearchValue, setCryptoDepositSearchValue] =
		useState<string>('');
	// eslint-disable-next-line
	const [cryptoBuySearchValue, setCryptoBuySearchValue] =
		useState<string>('');
	// eslint-disable-next-line
	const [cryptoBuySearchField, setCryptoBuySearchField] =
		useState<string>('transaction_hash');
	const [fiatSearchField, setFiatSearchField] =
		useState<string>('tagger_email');
	const [fiatSearchValue, setFiatSearchValue] = useState<string>('');
	// const [checked, setChecked] = useState<boolean>(false)

	///slected row to export
	const [rowsToExport, setRowsToExport] = useState<any[]>([]);

	const [collections, { reload, paginateProps }] =
		usePartnersPaginate<any>(
			{
				route: ROUTES.Partners_PAYOUT_ALL,
				params: {
					filters: { ...filters },
				},
				filterData: (data) => {
					console.log({ data });
					return data.payoutList;
				},
			},
			[search, filters],
		);

	console.log({ rowsToExport, paginateProps, filters });
	const exportToExcel = (data: any, fileName: any, exportType: any) => {
		exportFromJSON({ data, fileName, exportType });
	};

	const handleCheckboxChange = (e: any, item: any) => {
		console.log({ checkED: e.target.checked });

		if (e.target.checked) {
			const updateItem = {
				...item,
				cryptoQuantityCharged: item?.quoteDetail?.coinQuantityCharged,
				receivingCurrency: item?.quoteDetail?.receivingCurrency,
				sendingCurrency: item?.quoteDetail?.sendingCurrency,
				receivingCountry: item?.quoteDetail?.receivingCountry,
				sendingCountry: 'US',
				coin: item?.quoteDetail?.coin,
				user_id: item?.quoteDetail?.user_id,
				user_email: item?.quoteDetail?.userEmail,
				quantity: item?.quoteDetail?.quantity,
				amount: item?.quoteDetail?.amount,
				type: item?.quoteDetail?.quoteType,
				bankWireFee: item?.quoteDetail?.bankWireFee,
				transferType: item?.quoteDetail?.transferType,
				recipientRelationship: item?.quoteDetail?.recipientRelationship,
				remittancePurpose: item?.quoteDetail?.remittancePurpose,
				amountInLocalCurrency: item?.provider_response?.amount,
			};

			delete updateItem['provider_response'];
			delete updateItem['quoteDetail'];
			console.log({ updateItem });

			setRowsToExport([...rowsToExport, updateItem]);
		} else {
			setRowsToExport(rowsToExport.filter((i: any) => i._id !== item._id));
		}
	};

	// selection for filter switch
	// const changeSelections = (status: string) => {
	//   updateFilters?.('transc_status', status === 'all' ? '' : status);

	//   setStatus(status);
	// };

	// switch for chnaging fiat to crytop++++++++++++++
	const ChangeManager = async () => {
		console.log('CHANGE MANAGER');

		await resetAllSearchFields();

		/* 	if (Change === 'giftcard') {
			setChange('mobileTopUp');
			reload2();
		}

		if (Change === 'mobileTopUp') {
			setChange('giftcard');
			reload();
		} */
	};

	useEffect(() => {
		if (Change === 'giftcard') reload();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	useEffect(() => {
		reload();
		// eslint-disable-next-line
	}, []);

	// ? Method to handle `View Details` for CRYPTO DEPOSIT records

	// ? SEARCH MODULE

	// ? Method to reset all search fields
	const resetAllSearchFields = () => {
		setFiatSearchValue(() => '');
		setFiatSearchField(() => 'tagger_email');

		setCryptoBuySearchValue(() => '');
		setCryptoDepositSearchValue(() => '');
	};
	// * End of resetAllSearchFields();

	// ? Method to Search Fiat Tickets
	const handleFiatSearch = async (event: any) => {
		event.preventDefault();
		reload();
	};
	// * End of handleFiatSearch()

	// ? Method to Search Crypto Buy Tickets
	// const handleCryptoBuySearch = async (event: any) => {
	//   event.preventDefault();
	//   reload2();
	// };
	// * End of handleCryptoBuySearch()

	// ? Method to Search Crypto Deposit Tickets
	const handleCryptoDepositSearch = async (event: any) => {
		event.preventDefault();
	};
	// * End of handleCryptoDepositSearch()

	// ? Methods to render the dropdown title
	const renderFiatDropdown = () => {
		switch (fiatSearchField) {
			case 'name':
				return 'Name';
		}
	};

	// const renderCryptoBuyDropdown = () => {
	//   switch (cryptoBuySearchField) {
	//     case 'tagger_email':
	//       return 'Email';

	//     case 'tagger_id':
	//       return 'Customer ID';

	//     case 'user_destination_walletAddress':
	//       return 'Wallet Address';
	//   }
	// };

	const renderCryptoDepositDropdown = () => {
		switch (cryptoDepositSearchField) {
			case 'transaction_hash':
				return 'Transaction Hash';

			case 'source_address':
				return 'Source Address';
		}
	};

	const renderSearchBar = () => {
		return (
			<form className={classes.userSearchForm} onSubmit={handleFiatSearch}>
				<div className={classes.searchBarSection}>
					<div className={classes.searchInputSection}>
						<input
							type="text"
							placeholder="Search TxID"
							value={filters?.transactionId ? filters?.transactionId : ''}
							onChange={(event) =>
								updateFilters('transactionId', event?.target.value)
							}
						/>

						<Menu
							menuButton={
								<MenuButton className={classes.dropdownBtn}>
									<span>{renderFiatDropdown()}</span>
									<img src={arrowDownBlack} alt="arrow-img" width={10} />
								</MenuButton>
							}
							transition
							menuClassName="drop-down"
							onItemClick={(e: any) => setFiatSearchField(e.value)}
						>
							<MenuItem
								value="Transaction Id"
								className={
									fiatSearchField === 'Transaction Id'
										? 'dropdown-item-selected'
										: ''
								}
							>
								Transaction Id
							</MenuItem>
						</Menu>
					</div>
					{/* <Button type="submit">Search</Button>

						<Button onClick={resetAllSearchFields}>Reset</Button> */}
				</div>
			</form>
		);
	};
	const addFilter = (key: string, value: string) => {
		let updatedFilter: any = filters['filters']
			? { filters: JSON.parse(filters['filters']) }
			: { filters: {} };
		console.log({ updsd: updatedFilter });
		if (updatedFilter.filters[key]) {
			updatedFilter.filters[key] = value;
		} else {
			updatedFilter.filters = {
				...updatedFilter.filters,
				[key]: value,
			};
		}
		console.log({ updatedFilter });
		updateFilters?.('filter', JSON.stringify(updatedFilter.filters));
		/* 	setStatus(value); */
	};
	console.log({ filters });

	return (
		<PartnerScreen className={classes.container}>
			{/* <div className={classes.searchBarSection}>{renderSearchBar()}</div>  */}
			<div className={classes.header}>
				{/* 	<Filters
					filters={filters}
					updateFilters={updateFilters}
					filterList={filterList}
				/> */}
				<MenuDropDownFilter
					menuList={filterList[0].options.map((option) => option.value)}
					onItemSelect={(e) => {
						console.log({ e });
						if (e.value != 'All') {
							updateFilters('createdAt', e.value);
						} else {
							removeFilters('createdAt');
						}
					}}
				/>
				<MenuDropDownFilter
					menuList={filterList[1].options.map((option) => option.value)}
					onItemSelect={(e) => {
						console.log({ e });
						if (e.value != 'All Products') {
							updateFilters('productType', e.value);
						} else {
							removeFilters('productType');
						}
					}}
				/>
				<MenuDropDownFilter
					menuList={filterList[3].options.map((option) => option.value)}
					onItemSelect={(e) => {
						console.log({ e });
						if (e.value != 'All') {
							updateFilters('status', e.value.toLowerCase());
						} else {
							removeFilters('status');
						}
					}}
				/>
				<>{renderSearchBar()}</>
				<button
					// style={{ padding: '17px' }}
					onClick={() => {
						if (rowsToExport && rowsToExport?.length) {
							exportToExcel(rowsToExport, 'payout_transactions', 'csv');
						} else {
							exportToExcel(
								collections.map((cl) =>
									flattenObject(cl, '', [
										'debitParty',
										'creditParty',
										'profit',
										'totalMargin',
										'partnerComission',
										'originalfxRate',
										'originalAmount',
										'quotes',
									]),
								),
								'payout_transactions',
								'csv',
							);
						}
					}}
				>
					Export
				</button>
				{/* Button to Switch Fiat to Crypto +++++++++++++++++++ */}

				{/* 	<button onClick={ChangeManager}>
					Switch to{' '}
					{Change === 'giftcard'
						? 'Topup Transactions'
						: 'GiftCards Transactions'}
				</button>
 */}
				{/* Despoite Ticker CreatinG Button++++++++++++ */}
				{Change === 'Buy' ? (
					<button onClick={() => {}}>Create Deposit Request</button>
				) : null}

				{/* <img src={Filtericon} alt="filtericon" /> */}
			</div>

			<div className={classes.transaction_module}>
				<table>
					<thead>
						<tr>
							<th>Date</th>
							<th>User ID</th>
							<th>Transaction ID</th>
							<th>Product type</th>
							<th>Crypto/Fiat Currency</th>
							<th>Quantity/Amount</th>
							<th>status</th>
						</tr>
					</thead>
					<tbody>
						{collections &&
							collections.map((values: any) => {
								let data: any = {};
								if (values.gc_response) {
									//type
									data.createdAt = values.createdAt;
									data.product = 'Giftcard';
									data.userId = values?.encryptus_userID || 'n/a';
									data.transactionId = values?.encryptus_order_id;
									data.crypto = values?.crypto_currency;
									data.amountOrQuantity =
										values?.gc_response?.gc_total_amount;
									data.status = values.transaction_status
										? values.transaction_status
										: 'n/a';
								}
								if (values.mobile_topup_response) {
									//type
									data.createdAt = values.createdAt;
									data.product = 'Mobile Topup';
									data.userId = values?.encryptus_userID || 'n/a';
									data.transactionId = values.encryptus_order_id;
									data.crypto = values?.crypto_coin;
									data.amountOrQuantity = values?.crypto_charged_quantity;
									data.status = values.transaction_status
										? values.transaction_status
										: 'n/a';
								}
								if (values.provider_response) {
									const productType = values.quoteDetail.transferType;
									const capitalized =
										productType.charAt(0).toUpperCase() +
										productType.slice(1);
									data.createdAt = values.createdAt;
									data.product =
										capitalized == 'Bank' ? 'BankWire' : 'Mobile Wallet';
									data.userId = values?.partner_userID || 'n/a';
									data.transactionId = values?.encryptus_order_id || 'n/a';
									data.crypto = values?.quoteDetail.coin;
									data.amountOrQuantity =
										values?.quoteDetail.coinQuantityCharged;
									data.status = values.transaction_status;
								}
								console.log({ data });
								return (
									<>
										<tr key={values.transc_status}>
											<td
												onDoubleClick={() =>
													copyText(DATE_CONV.dateConvert(data?.createdAt))
												}
											>
												{DATE_CONV.dateConvert(data?.createdAt)}
											</td>
											<td onDoubleClick={() => copyText(data?.userId)}>
												{data?.userId}
											</td>
											<td
												onDoubleClick={() =>
													copyText(data?.encryptus_order_id)
												}
											>
												{data?.transactionId}
											</td>
											<td onDoubleClick={() => copyText(data?.product)}>
												{data?.product}
											</td>
											<td onDoubleClick={() => copyText(data?.crypto)}>
												{data?.crypto}
											</td>
											<td
												onDoubleClick={() =>
													copyText(data?.amountOrQuantity)
												}
											>
												{data?.amountOrQuantity}
											</td>
											<td onDoubleClick={() => copyText(data?.status)}>
												{data?.status}
											</td>
											{/* <td>
												<button
													className={classes.DetailsBTN}
													onClick={() =>
														openModal(() => (
															<MobileToupsDetails
																collections={values}
																type="GC"
															/>
														))
													}
												>
													View Details
												</button>
											</td> */}

											<td>
												<input
													type="checkbox"
													onChange={(e) => handleCheckboxChange(e, values)}
													checked={
														rowsToExport.find((i: any) => {
															console.log({
																i: i._id,
																target: values._id,
																isChecked: i._id === values._id,
															});
															return i._id === values._id;
														})
															? true
															: false
													}
												/>
											</td>
										</tr>
									</>
								);
							})}
					</tbody>
				</table>
			</div>
			<Paginate {...paginateProps} />
		</PartnerScreen>
	);
}
