import React, { FC, Fragment, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { HTMLELEMENT, Size, THEMES, COLORS } from '../interfaces';
import classes from 'styles/Button.module.scss';
import Loader from 'react-loader-spinner';

interface Props extends HTMLELEMENT<HTMLButtonElement> {
    size?: Size;
    variant?: THEMES;
    link?: string;
    invert?: boolean;
    color?: string;
    disabled?: boolean;
    loading?: boolean;
}

const styles: {
    [size: string]: React.CSSProperties | undefined;
} = {
    s: { padding: 10, minWidth: 100, fontSize: 16 },
    m: { padding: 15, minWidth: 150, fontSize: 18 },
    l: { padding: 30, minWidth: 200, fontSize: 20 },
};

const disabledStyles: React.CSSProperties | undefined = {
    cursor: 'not-allowed',
    opacity: 0.5,
};

export default function Button({
    size = 'm',
    variant = THEMES.PRIMARY,
    link,
    invert,
    color,
    disabled,
    onClick,
    loading,
    children,
    ...rest
}: Props) {
    const primaryColor = useMemo(
        () => color || COLORS[variant],
        [color, variant],
    );
    const secondaryColor = '#fff';

    const { borderColor } = useMemo(
        () =>
            invert
                ? {
                    backgroundColor: secondaryColor,
                    borderColor: primaryColor,
                    textColor: primaryColor,
                }
                : {
                    backgroundColor: primaryColor,
                    borderColor: 'transparent',
                    textColor: secondaryColor,
                },
        [invert, primaryColor, secondaryColor],
    );

    const Wrapper: FC = useMemo(
        () => (link ? (p) => <Link {...p} to={link} /> : Fragment),
        [link],
    );

    return (
        <Wrapper>
            <button
                {...rest}
                onClick={disabled || loading ? undefined : onClick}
                style={{
                    backgroundColor: '#E8CB69',
                    border: `1px solid ${borderColor}`,
                    color: '#091A49',
                    fontWeight: 600,
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    ...rest.style,
                    ...styles[size],
                    ...(disabled ? disabledStyles : {}),
                }}
                className={`${classes.container} ${rest.className || ''}`}
            >
                {loading ? (
                    <Loader
                        type="ThreeDots"
                        color={invert ? primaryColor : '#fff'}
                        height={10}
                    // style={{textAlign:"center"}}
                    />
                ) : (
                    children
                )}
            </button>
        </Wrapper>
    );
}
