import { useModal } from '../context/modal';
import PartnerLogin from '../modals/PartnersLogin';
import classes from 'styles/NewHomepage.module.scss';
import { useHistory } from 'react-router-dom';
import Pregister from 'modals/Pregister';
import { usePartners } from 'context/partners';
import { useEffect } from 'react';

export const NewHomePage = ({
}) => {
    const { openModal } = useModal();
    const { Partner, Ptoken } = usePartners()
    const history = useHistory();
    useEffect(() => {
        // console.log({ Partner, Ptoken });
        if (Partner && Ptoken) {
            history.push('/dashboard');
        }
    }, [Partner, Ptoken]);
    return (
        <>
            <main>
                <div className={classes.landing_screen}>
                    <h1 className={classes.first_text}>Welcome To Encryptus</h1>
                    <p className={classes.first_text2}>
                        {' '}
                        Partner Login
                    </p>
                    <section className={classes.Btn_div}>
                        <button
                            className={classes.become_client}
                            onClick={() =>
                                openModal(PartnerLogin)
                            }
                        >
                            Partner Login
                        </button>
                        <button className={classes.become_client} onClick={() =>
                            openModal(Pregister)
                        }>
                            Partners Register
                        </button>
                    </section>
                </div>
                {/* <img src={fading_image} alt="background_logo" className="fading_logo" /> */}
            </main>
        </>
    );
};
