import AdminScreen from 'components/adminScreen';
import React, { useEffect, useState } from 'react';
import classes from 'styles/Transaction.module.scss';
import { useModal } from 'context/modal';
import { ROUTES } from 'config';
// import axios from 'axios';
// import { toast } from 'react-toastify';
import Button from 'components/Button';
import Filters from 'components/Filters';
import { FilterListItem } from 'interfaces';
import useFilters from 'hooks/useFilters';
import { DATE_CONV } from 'utils/DateConv';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { copyText } from 'utils/CopyContent';
import PfiatWithdrawDetail from 'modals/PfiatWithdrawDetail';
import PcryptoWithdrawDetail from 'modals/PcryptoWithdrawDetail';
import { useSearch } from 'context/search';
import Paginate from 'components/Paginate';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import arrowDownBlack from 'assets/arrow-down-black.svg';
import Arrowdown from 'assets/Arrow - Down 4.png';
import usePartnersPaginate from 'hooks/usePartnersPaginate';
// import { usePartners } from 'context/partners';
import exportFromJSON from 'export-from-json';
import { toast } from 'react-toastify';
import axios from 'axios';

const filterList: FilterListItem[] = [
	{
		field: 'createdAt',
		label: 'Created',
		options: [
			{ value: '7', label: 'Last 7 days' },
			{ value: '15', label: 'Last 15 days' },
			{ value: '30', label: 'Last 30 days' },
		],
	},
];

export default function WithdrawTransactions() {
	const { openModal } = useModal();
	// const { headers } = usePartners();
	const { search } = useSearch();
	// States?
	const [Change, setChange] = useState('Fiat');

	// ? Search Bar Values
	const [fiatSearchValue, setFiatSearchValue] = useState<string>('');
	const [fiatSearchField, setFiatSearchField] =
		useState<string>('tagger_email');

	const [cryptoBuySearchValue, setCryptoBuySearchValue] =
		useState<string>('');
	const [cryptoBuySearchField, setCryptoBuySearchField] =
		useState<string>('tagger_email');

	const [status, setStatus] = useState<string>('all');

	const { filters, updateFilters } = useFilters();

	const [collections, { reload, paginateProps }] =
		usePartnersPaginate<any>(
			{
				route: ROUTES.Admin_Fetch_CWithdrawal_Ticket,
				params: {
					filters: { tagger_email: fiatSearchValue, ...filters },
				},
				filterData: (data) => {
					console.log({ ddd: data });
					return data.data.info.list;
				},
			},
			[search, filters],
		);

	const [
		collections2,
		{ reload: reload2, paginateProps: paginateProps2 },
	] = usePartnersPaginate<any>(
		{
			route: ROUTES.Partners_CRYPTO_WITHDRAW_TICKETS,
			params: {
				filters: {
					[cryptoBuySearchField]: cryptoBuySearchValue,
					...filters,
				},
			},
			filterData: (data) => {
				console.log({ ddd: data });
				return data.data.info.list;
			},
		},
		[search, filters],
	);

	// const approval = async (id: string, bool: boolean) => {
	//   try {
	//     const body = {
	//       final_approval: bool,
	//     };
	//     await axios.patch(ROUTES.FIAT_WITHDRAW_APPROVAL(id), body, {
	//       headers,
	//     });
	//     toast.success('Completed Successfully');
	//     reload();
	//   } catch (err: any) {
	//     toast.warning(err?.response?.data?.message);
	//     console.log(err);
	//   }
	// };

	// Crypto Approval++++++++++++++++++++++++++++++++++
	// const CryptoApproval = async (id: string, bool: boolean) => {
	//   try {
	//     const body = {
	//       final_approval: bool,
	//     };

	//     await axios.patch(ROUTES.CRYPTO_WITHDRAW_APPROVAL(id), body, {
	//       headers,
	//     });
	//     toast.success('Completed Successfully');
	//     reload2();
	//   } catch (err: any) {
	//     toast.warning(err?.response?.data?.message);
	//     console.log(err);
	//   }
	// };
	// fiat render function++++++++++++++++
	// const rerender = () => {
	//   reload();
	// };

	// ????????????selection for filter switch ???????????????
	const changeSelections = (status: any) => {
		updateFilters?.('transc_status', status === 'all' ? '' : status);

		setStatus(status);
	};

	// ????????????switch?????????????????????????????
	// switch for chnaging fiat to crytop++++++++++++++
	const ChangeManager = async () => {
		console.log('CHANGE MANAGER');

		await resetAllSearchFields();

		if (Change === 'Fiat') {
			setChange('Crypto');
			reload2();
		}

		if (Change === 'Crypto') {
			setChange('Fiat');
			reload();
		}
	};

	useEffect(() => {
		if (Change === 'Fiat') reload();
		else reload2();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
	useEffect(() => {
		reload();
		reload2();
		// eslint-disable-next-line
	}, []);

	// ? Method to reset all search fields
	const resetAllSearchFields = () => {
		setFiatSearchValue(() => '');
		setFiatSearchField(() => 'tagger_email');

		setCryptoBuySearchValue(() => '');
	};
	// * End of resetAllSearchFields();

	// ? Method to Search Fiat Tickets
	const handleFiatSearch = async (event: any) => {
		event.preventDefault();
		reload();
	};
	// * End of handleFiatSearch()

	// ? Method to Search Crypto Tickets
	const handleCryptoSearch = async (event: any) => {
		event.preventDefault();
		reload2();
	};
	// * End of handleCryptoSearch()
	// const handlevalue = async (id: string, type: string) => {
	//   try {
	//     const body = {
	//       transac_type: type,
	//     };
	//     const data = await axios.patch(ROUTES.Processing_Route(id), body, {
	//       headers,
	//     });
	//     if (data) {
	//       toast.success('Success');
	//       reload();
	//       reload2();
	//     }
	//   } catch (err) {
	//     console.log(err);
	//     toast.warning('Not Set To Processing');
	//   }
	// };

	// * END OF SEARCH MODULE

	const renderSearchBar = () => {
		// ? FIAT
		if (Change === 'Fiat') {
			return (
				<form
					className={classes.userSearchForm}
					onSubmit={handleFiatSearch}
				>
					<h4>Fiat Withdrawals</h4>

					<div className={classes.searchBarSection}>
						<div className={classes.searchInputSection}>
							<input
								type="text"
								placeholder="Search Email"
								value={fiatSearchValue}
								onChange={(event) =>
									setFiatSearchValue(event?.target.value)
								}
							/>
							<Menu
								menuButton={
									<MenuButton className={classes.dropdownBtn}>
										<span>{renderFiatDropdown()}</span>
										<img src={arrowDownBlack} alt="arrow-img" width={10} />
									</MenuButton>
								}
								transition
								menuClassName="drop-down"
								onItemClick={(e: any) => setFiatSearchField(e.value)}
							>
								<MenuItem
									value="tagger_email"
									className={
										fiatSearchField === 'tagger_email'
											? 'dropdown-item-selected'
											: ''
									}
								>
									Email
								</MenuItem>
							</Menu>
						</div>
						<Button type="submit">Search</Button>

						<Button onClick={resetAllSearchFields}>Reset</Button>
					</div>
				</form>
			);
		} else {
			// ? CRYPTO
			return (
				<form
					className={classes.userSearchForm}
					onSubmit={handleCryptoSearch}
				>
					<h4>Crypto Transactions</h4>

					<div className={classes.searchBarSection}>
						<div className={classes.searchInputSection}>
							<input
								type="text"
								value={cryptoBuySearchValue}
								onChange={(event) =>
									setCryptoBuySearchValue(() => event.target.value)
								}
								placeholder={
									cryptoBuySearchField === 'tagger_email'
										? 'Search Email'
										: cryptoBuySearchField === 'tagger_id'
										? 'Search ID'
										: 'Search Wallet Address'
								}
							/>
							<Menu
								menuButton={
									<MenuButton className={classes.dropdownBtn}>
										<span>{renderCryptoBuyDropdown()}</span>
										<img src={arrowDownBlack} alt="arrow-img" width={10} />
									</MenuButton>
								}
								transition
								menuClassName="drop-down"
								onItemClick={(e: any) => setCryptoBuySearchField(e.value)}
							>
								<MenuItem
									value="tagger_email"
									className={
										fiatSearchField === 'tagger_email'
											? 'dropdown-item-selected'
											: ''
									}
								>
									Email
								</MenuItem>
								<MenuItem
									value="tagger_id"
									className={
										fiatSearchField === 'tagger_id'
											? 'dropdown-item-selected'
											: ''
									}
								>
									Customer ID
								</MenuItem>
								<MenuItem
									value="user_destination_walletAddress"
									className={
										fiatSearchField === 'user_destination_walletAddress'
											? 'dropdown-item-selected'
											: ''
									}
								>
									Wallet Address
								</MenuItem>
							</Menu>
						</div>
						<Button type="submit">Search</Button>

						<Button onClick={resetAllSearchFields}>Reset</Button>
					</div>
				</form>
			);
		}
	};

	// ? Method to rendert the title in the dropdown
	const renderFiatDropdown = () => {
		switch (fiatSearchField) {
			case 'name':
				return 'Name';

			case 'phone_no':
				return 'Phone No';

			case 'tagger_email':
				return 'Email';
		}
	};

	const renderCryptoBuyDropdown = () => {
		switch (cryptoBuySearchField) {
			case 'tagger_email':
				return 'Email';

			case 'tagger_id':
				return 'Customer ID';

			case 'user_destination_walletAddress':
				return 'Wallet Address';
		}
	};

	const renderStatusDropdownTitle = () => {
		switch (status) {
			case 'all':
				return 'All Transactions';

			case 'pending':
				return 'Pending';

			case 'approved':
				return 'Approved';

			case 'rejected':
				return 'Rejected';

			case 'processing':
				return 'Processing';

			default:
				return 'All Transactions';
		}
	};
	// * End of renderDropdownTitle methods

	const exportToExcel = (data: any, fileName: any, exportType: any) => {
		exportFromJSON({ data, fileName, exportType });
	};
	
	const approve_withdraw = async (id: string) => {
		try {
			await axios({
				method: 'POST',
				url: ROUTES.Admin_CWithdrawal_Ticket_Req,
				data: {
					final_approval : true
				}
			})
			toast.success('Ticket approved successfully')
		} catch (e: any) {
			toast.error(e?.message);
			console.log({ e });
		}
	}

	return (
		<AdminScreen className={classes.container}>
			<div className={classes.header}>
				{
					<Menu
						menuButton={
							<MenuButton className={classes.statusDropdown}>
								<span>{renderStatusDropdownTitle()}</span>
								<img src={Arrowdown} alt="arrow-img" width={10} />
							</MenuButton>
						}
						transition
						menuClassName="drop-down"
						onItemClick={(e: any) => changeSelections(e.value)}
					>
						<MenuItem
							value="all"
							className={status === 'all' ? 'dropdown-item-selected' : ''}
						>
							All Transactions
						</MenuItem>
						<MenuItem
							value="pending"
							className={
								status === 'pending' ? 'dropdown-item-selected' : ''
							}
						>
							Pending
						</MenuItem>
						<MenuItem
							value="approved"
							className={
								status === 'approved' ? 'dropdown-item-selected' : ''
							}
						>
							Approved
						</MenuItem>
						<MenuItem
							value="rejected"
							className={
								status === 'rejected' ? 'dropdown-item-selected' : ''
							}
						>
							Rejected
						</MenuItem>
						<MenuItem
							value="processing"
							className={
								status === 'processing' ? 'dropdown-item-selected' : ''
							}
						>
							Processing
						</MenuItem>
					</Menu>
				}
				<button
					// style={{ padding: '17px' }}
					onClick={() => {
						Change === 'Fiat'
							? exportToExcel(collections, 'Fiat Withdraw', 'csv')
							: exportToExcel(collections2, 'Crypto Withdraw', 'csv');
					}}
				>
					Export
				</button>

				<Filters
					filters={filters}
					updateFilters={updateFilters}
					filterList={filterList}
				/>
				{/* Button to Switch Fiat to Crypto +++++++++++++++++++ */}

				<button onClick={ChangeManager}>
					Switch to{' '}
					{Change === 'Fiat' ? 'Crypto Transaction' : 'Fiat Transactions'}
				</button>
			</div>

			<div className={classes.searchBarSection}>{renderSearchBar()}</div>

			<div className={classes.transaction_module}>
				{Change === 'Fiat' ? (
					// ? FIAT WITHDRAW TABLE
					<table>
						<thead>
							<tr>
								<th>Date</th>
								<th>Email</th>
								<th>
									Withdrawal
									<br />
									Ticket ID
								</th>
								<th>Bank Account</th>
								<th>Amount</th>
								<th>Currency</th>
								<th>Status</th>
								<th>Details</th>
							</tr>
						</thead>
						<tbody>
							{collections &&
								collections.map((values: any) => {
									return (
										<>
											<tr key={values?.transc_status}>
												<td
													onDoubleClick={() =>
														copyText(
															DATE_CONV.dateConvert(values?.createdAt),
														)
													}
												>
													{DATE_CONV.dateConvert(values?.createdAt)}
												</td>
												<td>
													{values?.tagger_email
														? values?.tagger_email
														: 'N/A'}
												</td>
												<td
													onDoubleClick={() =>
														copyText(values?.unq_ref_id)
													}
												>
													{values?.unq_ref_id}
												</td>
												<td>
													{values?.bankAcc ? values?.bankAcc : 'N/A'}
												</td>
												<td
													onDoubleClick={() =>
														copyText(
															`${CURRENCY_CONV.fiatAmtConv(
																values?.withdraw_amt,
															)} ${
																values?.fiat_curr?.toUpperCase() ||
																values?.withdraw_curr?.toUpperCase()
															}`,
														)
													}
												>
													{CURRENCY_CONV.fiatAmtConv(values?.withdraw_amt)}
													&nbsp;
												</td>
												<td>
													{values?.withdraw_curr.toUpperCase()
														? values?.withdraw_curr
														: 'N/A'}
												</td>
												<td
													onDoubleClick={() =>
														copyText(values?.transc_status?.toUpperCase())
													}
													className={
														values?.transc_status === 'approved'
															? 'textStatusApproved'
															: values?.transc_status === 'pending' ||
															  values?.transc_status === 'processing'
															? 'textStatusPending'
															: 'textStatusRejected'
													}
												>
													{values?.transc_status?.toUpperCase()}
												</td>
												<td>
													<button
														className={classes.DetailsBTN}
														onClick={() =>
															openModal(() => (
																<PfiatWithdrawDetail
																	transactionData={values}
																/>
															))
														}
													>
														View Details
													</button>
												</td>
											</tr>
										</>
									);
								})}
						</tbody>
					</table>
				) : (
					// ? CRYPTO WITHDRAW TABLE
					<div>
						<table>
							<thead>
								<tr>
									<th>Date</th>
									<th>Email</th>
									<th>Amount</th>
									<th>Transaction Reference ID</th>
									<th>Crypto Asset</th>
									<th>View Details</th>
									<th>Transaction Status</th>
								</tr>
							</thead>

							<tbody>
								{collections2 &&
									collections2.map((values: any) => {
										return (
											<>
												<tr key={values.transc_status}>
													<td
														onDoubleClick={() =>
															copyText(
																DATE_CONV.dateConvert(values?.createdAt),
															)
														}
													>
														{DATE_CONV.dateConvert(values?.createdAt)}
													</td>
													<td>
														{' '}
														{values?.tagger_email
															? values.tagger_email
															: 'N/A'}{' '}
													</td>
													<td
														onDoubleClick={() =>
															copyText(
																`${CURRENCY_CONV.cryptoAmtConv(
																	values?.withdraw_amt,
																)} ${values?.withdraw_curr?.toUpperCase()}`,
															)
														}
													>
														{CURRENCY_CONV.cryptoAmtConv(
															values?.withdraw_amt,
														)}
														&nbsp;
													</td>
													<td
														onDoubleClick={() =>
															copyText(values?.unq_ref_id)
														}
													>
														{values?.unq_ref_id}
													</td>
													<td
														onDoubleClick={() =>
															copyText(values.received_amt_curr)
														}
														className={classes.tagger_id}
													>
														{values.withdraw_curr}
													</td>
													<td
														onDoubleClick={() =>
															copyText(
																values?.transc_status?.toUpperCase(),
															)
														}
														className={
															values?.transc_status === 'approved'
																? 'textStatusApproved'
																: values?.transc_status === 'pending' ||
																  values?.transc_status === 'processing'
																? 'textStatusPending'
																: 'textStatusRejected'
														}
													>
														{values?.transc_status?.toUpperCase()}
													</td>
													<td>
													{values?.transc_status != 'approved' && <Button onClick={() => approve_withdraw(values?.id)}>Approve</Button>}
													{/* {values?.transc_status != 'approved' && values?.transc_status === 'pending' && <Button>Reject</Button>} */}
												</td>
													<td>
														<button
															className={classes.DetailsBTN}
															onClick={() =>
																openModal(() => (
																	<PcryptoWithdrawDetail
																		transactionData={values}
																	/>
																))
															}
														>
															View Details
														</button>
													</td>
												</tr>
											</>
										);
									})}
							</tbody>
						</table>
					</div>
				)}
			</div>
			{Change === 'Fiat' && <Paginate {...paginateProps} />}
			{Change === 'Crypto' && <Paginate {...paginateProps2} />}
		</AdminScreen>
	);
}
