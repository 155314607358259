import axios from 'axios';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { ROUTES } from '../config';
import useLocalStorage from '../hooks/useLocalStorage';
import { ANY, LoginForm } from '../interfaces';
import { date } from 'yup';

// Interface++++++++++++
const AdminContext = createContext({
	Admin: ANY,
	Atoken: ANY,
	login: async (
		data: LoginForm,
		ifLogged?: () => any,
		// ifOTP?: (email: string) => any,
	) => ANY,
	logout: async () => ANY,
	// sendOTP: async (formData: OTPForm, cb?: () => any) => ANY,
	headers: ANY,
	getAdmin: async () => ANY,
});

export const AdminProvider: FC = ({ children }) => {
	const [Admin, setAdmin] = useState<any>();
	const [Atoken, AsetToken] = useLocalStorage<string | null>(
		'Atoken',
		null,
	);
	const history = useHistory();
	const headers = { authorization: `Bearer ${Atoken}` };
	const TOKEN_ROTATION_DURATION = 2700000;

	const getAdmin = async () => {
		if (!Atoken) return setAdmin(null);
		try {
			axios.defaults.withCredentials = false;
			const { data } = await axios.get(ROUTES.Admin_Info, {
				headers: { authorization: `Bearer ${Atoken}` },
			});
			if (!data) return;
			setAdmin(data.data.info);
			// console.log(data.data,"herer")
			localStorage.setItem('AdminEmail', data?.data.info.email);
			// history.push('/dashbaord')
		} catch (error: any) {
			setAdmin(null);
			if (
				error?.response?.status === 401
				// history.location.pathname !== 'Admin/verifiedEmail'
			)
				// history.push('/');
				return;
		}
	};

	// ? Method that is used to request a Refresh Token from the backend and set it in LocalStorage
	const setRefreshToken = async () => {
		try {
			let email = localStorage.getItem('AdminEmail');

			const { data }: any = await axios.post(
				ROUTES.Admin_Refresh_Token,
				{ email },
				{
					headers: { authorization: `Bearer ${Atoken}` },
				},
			);

			// ? Set the newly generated Refresh Token in LocalStorage
			AsetToken(data.accessToken);
		} catch (error) {
			logout();
		}
	};
	// * End of setRefreshToken();

	useEffect(() => {
		console.log('Called');
		getAdmin();

		// ? Checks if Access Token is set
		// ? # If true, setRefreshToken() is called every 'TOKEN_ROTATION_DURATION' seconds
		if (Atoken) {
			const interval = setInterval(() => {
				setRefreshToken();
			}, TOKEN_ROTATION_DURATION);

			return () => clearInterval(interval); // ? Clearning the Interval to prevent Memory Leaks
		}

		// eslint-disable-next-line
	}, [Atoken]);

	// console.log({ Atoken });

	const login = async (
		{ email, password }: LoginForm,
		ifLogged?: () => any,
	) => {
		try {
			axios.defaults.withCredentials = false;
			console.log(email);
			console.log(password);
			const { data }: any = await axios.post(`${ROUTES.Admin_Login}`, {
				email: email,
				password: password,
			});
			const { access_token } = data;
			if (access_token) {
				history.push('/admin_access');
				AsetToken(access_token);
				ifLogged?.();
				return { success: `Logged In!` };
			}
		} catch (error: any) {
			toast.warning(error?.response?.data?.message);
			return { error: error?.response?.data?.message };
		}
	};

	const logout = async () => {
		try {
			axios.defaults.withCredentials = false;
			/* const { data }: any = await axios.get(ROUTES.Admin_Logout, {
				headers,
			}); */
			AsetToken(null);
			localStorage.removeItem('AdminEmail');
			/* if (data.data.loggedOut) {
				console.log('true');
				history.push('/admin');
				toast.success('Logged Out');
			} */
			history.push('/admin');
			toast.success('Logged Out');
		} catch (error: any) {
			return { error: error?.response?.data?.message };
		}
	};

	// const sendOTP = async (formData: OTPForm, cb?: () => any) => {
	//     try {
	//         const { data }: any = await axios.post(ROUTES.OTP, formData);
	//         if (data.accessToken) {
	//             PsetToken(data.accessToken);
	//             cb?.();
	//             history.push('Admin/dashboard');

	//             return { success: `Logged In!` };
	//         }
	//     } catch (error: any) {
	//         console.log({ error });
	//         toast.warn('Please Enter Valid OTP');
	//         return { error: error?.response?.data?.message };
	//     }
	// };

	return (
		<AdminContext.Provider
			value={{
				Admin,
				Atoken,
				login,
				logout,
				headers,
				// sendOTP,
				getAdmin,
			}}
		>
			{children}
		</AdminContext.Provider>
	);
};

export const useAdmin = () => useContext(AdminContext);
