import PartnerScreen from 'components/partnersScreen';
import { useEffect, useState } from 'react';
import classes from 'styles/Transaction.module.scss';
import { useModal } from 'context/modal';
import { ROUTES } from 'config';
import PcryptoDepositDetails from 'modals/PcryptoDepositDetail';
import PfiatDepositDetails from 'modals/PfiatDepositDetail';
// import DepositeTicket from 'modals/DepositeTicket';
import Button from 'components/Button';
import Filters from 'components/Filters';
import { CurrencyType, FilterListItem } from 'interfaces';
import useFilters from 'hooks/useFilters';
import { useSearch } from 'context/search';
import Paginate from 'components/Paginate';
import { DATE_CONV } from 'utils/DateConv';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { copyText } from 'utils/CopyContent';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import arrowDownBlack from 'assets/arrow-down-black.svg';
import Arrowdown from 'assets/Arrow - Down 4.png';
// import { usePartners } from 'context/partners';
import usePartnersPaginate from 'hooks/usePartnersPaginate';
import exportFromJSON from 'export-from-json';
import { flattenObject } from 'utils';

const filterList: FilterListItem[] = [
	{
		field: 'createdAt',
		label: 'Created',
		options: [
			{ value: '7', label: 'Last 7 days' },
			{ value: '15', label: 'Last 15 days' },
			{ value: '30', label: 'Last 30 days' },
			{ value: '90', label: 'Last 90 days' },
		],
	},
];

// This is deposit transaction for crypto and fiat+++++++
export default function PStatement() {
	const { openModal } = useModal();
	// const { headers } = usePartners();
	// States?
	const [Change, setChange] = useState('Fiat');
	const { search } = useSearch();
	const { filters, updateFilters } = useFilters();
	// console.log({ filters });

	const [status, setStatus] = useState('all');

	// ? Search Bar Values

	const [cryptoDepositSearchField, setCryptoDepositSearchField] =
		useState<string>('transaction_hash');
	const [cryptoDepositSearchValue, setCryptoDepositSearchValue] =
		useState<string>('');
	const [cryptoBuySearchValue, setCryptoBuySearchValue] =
		useState<string>('');
	const [cryptoBuySearchField, setCryptoBuySearchField] =
		useState<string>('transaction_hash');
	const [fiatSearchField, setFiatSearchField] =
		useState<string>('tagger_email');
	const [depositID, setDepositID] = useState<string>('');
	const [fiatSearchValue, setFiatSearchValue] = useState<string>('');
	// const [checked, setChecked] = useState<boolean>(false)

	const [collections, { reload, paginateProps }] =
		usePartnersPaginate<any>(
			{
				route: ROUTES.Partners_ALL_TICKETS,
				params: {
					filters: {
						tagger_email: fiatSearchValue,
						...filters,
						tagger_id: depositID,
					},
				},
				filterData: (data) => {
					console.log({ ddd: data });
					return data.payoutList;
				},
			},
			[search, filters],
		);
	const [
		collections2,
		{ reload: reload2, paginateProps: paginateProps2 },
	] = usePartnersPaginate<any>(
		{
			route: ROUTES.Partners_ALL_TICKETS,
			params: {
				filters: {
					[cryptoDepositSearchField]: cryptoDepositSearchValue,
					...filters,
				},
			},
			filterData: (data) => {
				console.log({ ddd: data });
				return data.payoutList;
			},
		},
		[search, filters],
	);
	// const approval = async (id: string, bool: boolean) => {
	//   try {
	//     const body = {
	//       final_approval: bool,
	//     };
	//     await axios.patch(ROUTES.Fiat_Approval(id), body, {
	//       headers,
	//     });
	//     toast.success('Completed Successfully');
	//     reload();
	//   } catch (err: any) {
	//     toast.warning(err?.response?.data?.message);
	//     console.log(err);
	//   }
	// };

	// console.log({ collections2, collections });

	// Crypto Approval++++++++++++++++++++++++++++++++++
	// const CryptoApproval = async (id: string, bool: boolean) => {
	//   try {
	//     const body = {
	//       final_approval: bool,
	//     };
	//     await axios.patch(ROUTES.Crypto2_approval(id), body, {
	//       headers,
	//     });
	//     toast.success('Completed Successfully');
	//     reload2();
	//   } catch (err: any) {
	//     toast.warning(err?.response?.data?.message);
	//     console.log(err);
	//   }
	// };

	// const rerender = () => {
	//   reload();
	// };

	// // cryto buy flow
	// const Cryptorerender = () => {
	//   reload2();
	// };

	// selection for filter switch
	const changeSelections = (status: string) => {
		updateFilters?.('transc_status', status === 'all' ? '' : status);

		setStatus(status);
	};

	// switch for chnaging fiat to crytop++++++++++++++
	const ChangeManager = async () => {
		console.log('CHANGE MANAGER');

		await resetAllSearchFields();

		if (Change === 'Fiat') {
			setChange('Crypto');
			reload2();
		}

		if (Change === 'Crypto') {
			setChange('Fiat');
			reload();
		}
	};

	useEffect(() => {
		if (Change === 'Fiat') reload();
		else if (Change === 'Crypto') reload2();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	useEffect(() => {
		reload();
		reload2();
		// eslint-disable-next-line
	}, []);

	// ? Method to handle `View Details` for CRYPTO DEPOSIT records

	// ? SEARCH MODULE

	// ? Method to reset all search fields
	const resetAllSearchFields = () => {
		setFiatSearchValue(() => '');
		setFiatSearchField(() => 'tagger_email');

		setCryptoBuySearchValue(() => '');
		setCryptoDepositSearchValue(() => '');
	};
	// * End of resetAllSearchFields();

	// ? Method to Search Fiat Tickets
	const handleFiatSearch = async (event: any) => {
		event.preventDefault();
		reload();
	};
	// * End of handleFiatSearch()

	// ? Method to Search Crypto Buy Tickets
	const handleCryptoBuySearch = async (event: any) => {
		event.preventDefault();
		reload2();
	};
	// * End of handleCryptoBuySearch()

	// ? Method to Search Crypto Deposit Tickets
	const handleCryptoDepositSearch = async (event: any) => {
		event.preventDefault();
		reload2();
	};
	// * End of handleCryptoDepositSearch()

	const exportToExcel = (data: any, fileName: any, exportType: any) => {
		exportFromJSON({ data, fileName, exportType });
	};

	const linkHandler = (type: String, hash: String) => {
		switch (type) {
			case 'ETH':
				return `https://www.blockchain.com/eth/tx/${hash}`;
			case 'BTC':
				return `https://www.blockchain.com/btc/tx/${hash}`;
			case 'USDT':
				return `https://www.blockchain.com/eth/tx/${hash}`;
			case 'USDC':
				return `https://www.blockchain.com/eth/tx/${hash}`;
			case 'MATIC':
				return `https://polygonscan.com/tx/${hash}`;
			default:
				return;
		}
	};

	const sourceAddress = (currency: String, Address: String) => {
		switch (currency) {
			case 'ETH':
				return `https://www.blockchain.com/eth/address/${Address}`;
			case 'BTC':
				return `https://www.blockchain.com/btc/address/${Address}`;
			case 'USDT':
				return `https://www.blockchain.com/eth/address/${Address}`;
			case 'USDC':
				return `https://www.blockchain.com/eth/address/${Address}`;
			case 'MATIC':
				return `https://polygonscan.com/address/${Address}`;
			default:
				return;
		}
	};

	// * END OF SEARCH MODULE

	// ? Methods to render the dropdown title
	const renderFiatDropdown = () => {
		switch (fiatSearchField) {
			case 'name':
				return 'Name';

			case 'phone_no':
				return 'Phone No';

			case 'tagger_email':
				return 'Email';
		}
	};

	const renderCryptoBuyDropdown = () => {
		switch (cryptoBuySearchField) {
			case 'tagger_email':
				return 'Email';

			case 'tagger_id':
				return 'Customer ID';

			case 'user_destination_walletAddress':
				return 'Wallet Address';
		}
	};

	const renderCryptoDepositDropdown = () => {
		switch (cryptoDepositSearchField) {
			case 'transaction_hash':
				return 'Transaction Hash';

			case 'source_address':
				return 'Source Address';
		}
	};

	const renderStatusDropdownTitle = () => {
		switch (status) {
			case 'all':
				return 'All Transactions';

			case 'pending':
				return 'Pending';

			case 'approved':
				return 'Approved';

			case 'rejected':
				return 'Rejected';

			case 'processing':
				return 'Processing';

			default:
				return 'All Transactions';
		}
	};
	// * End of renderDropdownTitle methods;

	// const handlevalue = async (id: string, type: string) => {
	//   try {
	//     const body = {
	//       transac_type: type,
	//     };
	//     const data = await axios.patch(ROUTES.Processing_Route(id), body, {
	//       headers,
	//     });
	//     if (data) {
	//       toast.success('Success');
	//       reload();
	//       reload2();
	//     }
	//   } catch (err) {
	//     console.log(err);
	//     toast.warning('Not Set To Processing');
	//   }
	// };

	const renderSearchBar = () => {
		// ? FIAT
		if (Change === 'Fiat') {
			return (
				<form
					className={classes.userSearchForm}
					onSubmit={handleFiatSearch}
				>
					<h4>Statement</h4>

					<div className={classes.searchBarSection}>
						<div className={classes.searchInputSection}>
							<input
								type="text"
								placeholder={
									fiatSearchField == 'tagger_email'
										? 'Search Email'
										: 'deposit_id'
								}
								value={
									fiatSearchField == 'tagger_email'
										? fiatSearchValue
										: depositID
								}
								onChange={(event) =>
									fiatSearchField == 'tagger_email'
										? setFiatSearchValue(event?.target.value)
										: setDepositID(event?.target.value)
								}
							/>

							<Menu
								menuButton={
									<MenuButton className={classes.dropdownBtn}>
										<span>{renderFiatDropdown()}</span>
										<img src={arrowDownBlack} alt="arrow-img" width={10} />
									</MenuButton>
								}
								transition
								menuClassName="drop-down"
								onItemClick={(e: any) => setFiatSearchField(e.value)}
							>
								<MenuItem
									value="tagger_email"
									className={
										fiatSearchField === 'tagger_email'
											? 'dropdown-item-selected'
											: ''
									}
								>
									Email
								</MenuItem>
								<MenuItem
									value="deposit_id"
									className={
										fiatSearchField === 'deposit_id'
											? 'dropdown-item-selected'
											: ''
									}
								>
									Deposit ID
								</MenuItem>
							</Menu>
						</div>
						<Button type="submit">Search</Button>

						<Button onClick={resetAllSearchFields}>Reset</Button>
					</div>
				</form>
			);
		} else {
			// ? CRYPTO BUY
			if (Change === 'Buy') {
				return (
					<form
						className={classes.userSearchForm}
						onSubmit={handleCryptoBuySearch}
					>
						<h4>Crypto Buy</h4>

						<div className={classes.searchBarSection}>
							<div className={classes.searchInputSection}>
								<input
									type="text"
									value={cryptoBuySearchValue}
									onChange={(event) =>
										setCryptoBuySearchValue(() => event.target.value)
									}
									placeholder={
										cryptoBuySearchField === 'tagger_email'
											? 'Search Email'
											: cryptoBuySearchField === 'tagger_id'
											? 'Search ID'
											: 'Search Wallet Address'
									}
								/>
								<Menu
									menuButton={
										<MenuButton className={classes.dropdownBtn}>
											<span>{renderCryptoBuyDropdown()}</span>
											<img
												src={arrowDownBlack}
												alt="arrow-img"
												width={10}
											/>
										</MenuButton>
									}
									transition
									menuClassName="drop-down"
									onItemClick={(e: any) =>
										setCryptoBuySearchField(e.value)
									}
								>
									<MenuItem
										value="tagger_email"
										className={
											fiatSearchField === 'tagger_email'
												? 'dropdown-item-selected'
												: ''
										}
									>
										Email
									</MenuItem>
									<MenuItem
										value="tagger_id"
										className={
											fiatSearchField === 'tagger_id'
												? 'dropdown-item-selected'
												: ''
										}
									>
										Customer ID
									</MenuItem>
									<MenuItem
										value="user_destination_walletAddress"
										className={
											fiatSearchField === 'user_destination_walletAddress'
												? 'dropdown-item-selected'
												: ''
										}
									>
										Wallet Address
									</MenuItem>
								</Menu>
							</div>
							<Button type="submit">Search</Button>

							<Button onClick={resetAllSearchFields}>Reset</Button>
						</div>
					</form>
				);
			} else {
				// ? CRYPTO DEPOSIT
				return (
					<form
						className={classes.userSearchForm}
						onSubmit={handleCryptoDepositSearch}
					>
						<h4>Crypto Deposits </h4>

						<div className={classes.searchBarSection}>
							<div className={classes.searchInputSection}>
								<input
									type="text"
									value={cryptoDepositSearchValue}
									onChange={(event) =>
										setCryptoDepositSearchValue(event?.target.value)
									}
									placeholder={
										cryptoDepositSearchField === 'transaction_hash'
											? 'Search Txn Hash'
											: cryptoDepositSearchField === 'email'
											? 'source_address'
											: 'Search Address'
									}
								/>

								<Menu
									menuButton={
										<MenuButton className={classes.dropdownBtn}>
											<span>{renderCryptoDepositDropdown()}</span>
											<img
												src={arrowDownBlack}
												alt="arrow-img"
												width={10}
											/>
										</MenuButton>
									}
									transition
									menuClassName="drop-down"
									onItemClick={(e: any) =>
										setCryptoDepositSearchField(e.value)
									}
								>
									<MenuItem
										value="transaction_hash"
										className={
											fiatSearchField === 'transaction_hash'
												? 'dropdown-item-selected'
												: ''
										}
									>
										Transaction Hash
									</MenuItem>
									<MenuItem
										value="source_address"
										className={
											fiatSearchField === 'source_address'
												? 'dropdown-item-selected'
												: ''
										}
									>
										Source Address
									</MenuItem>
								</Menu>
							</div>

							<Button type="submit">Search</Button>

							<Button onClick={resetAllSearchFields}>Reset</Button>
						</div>
					</form>
				);
			}
		}
	};

	return (
		<PartnerScreen className={classes.container}>
			<div className={classes.searchBarSection}>{renderSearchBar()}</div>
			<div className={classes.header}>
				{
					<Menu
						menuButton={
							<MenuButton className={classes.statusDropdown}>
								<span>{renderStatusDropdownTitle()}</span>
								<img src={Arrowdown} alt="arrow-img" width={10} />
							</MenuButton>
						}
						transition
						menuClassName="drop-down"
						onItemClick={(e: any) => changeSelections(e.value)}
					>
						<MenuItem
							value="all"
							className={status === 'all' ? 'dropdown-item-selected' : ''}
						>
							All Transactions
						</MenuItem>
						<MenuItem
							value="pending"
							className={
								status === 'pending' ? 'dropdown-item-selected' : ''
							}
						>
							Pending
						</MenuItem>
						<MenuItem
							value="approved"
							className={
								status === 'approved' ? 'dropdown-item-selected' : ''
							}
						>
							Approved
						</MenuItem>
						<MenuItem
							value="rejected"
							className={
								status === 'rejected' ? 'dropdown-item-selected' : ''
							}
						>
							Rejected
						</MenuItem>
						<MenuItem
							value="processing"
							className={
								status === 'processing' ? 'dropdown-item-selected' : ''
							}
						>
							Processing
						</MenuItem>
					</Menu>
				}

				<button
					// style={{ padding: '17px' }}
					onClick={() => {
						Change === 'Fiat'
							? exportToExcel(
									collections.map((col) => flattenObject(col, '', [])),
									'Fiat Deposit',
									'csv',
							  )
							: exportToExcel(
									collections2.map((col) => flattenObject(col, '', [])),
									'Crypto Deposit',
									'csv',
							  );
						console.log(flattenObject(collections2, '', []));
					}}
				>
					Export
				</button>

				<Filters
					filters={filters}
					updateFilters={updateFilters}
					filterList={filterList}
				/>
				{/* Button to Switch Fiat to Crypto +++++++++++++++++++ */}

				{/* <button onClick={ChangeManager}>
					Switch to{' '}
					{Change === 'Fiat' ? 'Crypto Transaction' : 'Fiat Transactions'}
				</button> */}

				{/* Despoite Ticker CreatinG Button++++++++++++ */}
				{Change === 'Buy' ? (
					<button
						onClick={() =>
							// openModal(() => <DepositeTicket funcrun={reload2} />)
							null
						}
					>
						Create Deposit Request
					</button>
				) : null}

				{/* <img src={Filtericon} alt="filtericon" /> */}
			</div>

			<div className={classes.transaction_module}>
				{
					// ? FIAT TRANSACTIONS TABLE
					Change === 'Fiat' ? (
						<table>
							<thead>
								<tr>
									<th>Date</th>
									<th>Email</th>
									<th>Deposit Ticket ID</th>
									<th>Bank Account</th>
									<th>Amount</th>
									<th>Currency</th>
									<th>Transaction Status</th>
									<th>Details</th>
								</tr>
							</thead>
							<tbody>
								{collections.map((values: any) => {
                                    let amount = 'n/a';
                                    let currency = 'n/a';
                                    if(values?.received_amt && values?.received_amt_curr){
                                        amount = values?.received_amt
                                        currency = values?.received_amt_curr
                                    }
                                    if(values?.withdraw_amt && values?.withdraw_curr){
                                        amount = values?.withdraw_amt
                                        currency = values?.withdraw_curr
                                    }
                                    if(values?.depos_amt && values?.depos_curr){
                                        amount = values?.depos_amt
                                        currency = values?.depos_curr
                                    }
                    
									return (
										<>
											<tr key={values.transc_status}>
												<td
													onDoubleClick={() =>
														copyText(
															DATE_CONV.dateConvert(values?.createdAt),
														)
													}
												>
													{DATE_CONV.dateConvert(values?.createdAt)}
												</td>
												<td>{values?.tagger_email ? values?.tagger_email : 'N/A'}</td>
												<td onDoubleClick={() => copyText(values._id)}>
													{values.unq_ref_id}
												</td>
												<td>
													{values?.bankAcc ? values?.bankAcc : 'N/A'}
												</td>
												<td
													onDoubleClick={() =>
														copyText(
															`${CURRENCY_CONV.fiatAmtConv(
																amount,
															)} ${currency}`,
														)
													}
												>
													{CURRENCY_CONV.fiatAmtConv(amount)}
												</td>
												<td>
													{currency}
												</td>
												<td
													className={
														values?.transc_status === 'approved'
															? 'textStatusApproved'
															: values?.transc_status === 'pending' ||
															  values?.transc_status === 'processing'
															? 'textStatusPending'
															: 'textStatusRejected'
													}
													onDoubleClick={() =>
														copyText(values.transc_status.toUpperCase())
													}
												>
													{values.transc_status.toUpperCase()}
												</td>
												<td>
													<button
														className={classes.DetailsBTN}
														onClick={() =>
															openModal(() => (
																<PfiatDepositDetails
																	transactionData={values}
																/>
															))
														}
													>
														View Details
													</button>
												</td>
											</tr>
										</>
									);
								})}
							</tbody>
						</table>
					) : (
						<div>
							{/* // ? CRYPTO DEPOSIT TABLE */}
							<table>
								<thead>
									<tr>
										<th>Date</th>
										<th>Email</th>
										<th>Transaction Hash</th>
										<th>Source Address</th>
										<th>Amount</th>
										{/* <th>Email</th> */}
										<th>Crypto Asset</th>
										<th>View Details</th>
										<th>Transaction Status</th>
									</tr>
								</thead>
								<tbody>
									{/* CRypto deposit starts here */}
									{collections2.map((values: any) => {
										return (
											<tr key={values?.transc_status}>
												<td
													onDoubleClick={() =>
														copyText(
															DATE_CONV.dateConvert(values?.createdAt),
														)
													}
												>
													{DATE_CONV.dateConvert(values?.createdAt)}
												</td>
												<td> {values?.tagger_email ? values.tagger_email : 'N/A'} </td>
												<td
													onDoubleClick={() =>
														copyText(values?.transaction_hash)
													}
												>
													<a
														href={linkHandler(
															values?.received_amt_curr,
															values?.transaction_hash,
														)}
														target="_blank"
														rel="noreferrer"
														className={classes.ancor}
													>
														{values?.transaction_hash}
													</a>
												</td>
												<td
													onDoubleClick={() =>
														copyText(values?.source_address)
													}
												>
													<a
														href={sourceAddress(
															values?.received_amt_curr,
															values?.source_address,
														)}
														target="_blank"
														rel="noreferrer"
														className={classes.ancor}
													>
														{values?.source_address}
													</a>
												</td>
												<td
													onDoubleClick={() =>
														copyText(
															CURRENCY_CONV.cryptoAmtConv(
																values?.received_amt,
															),
														)
													}
													className={classes.taggeremail}
												>
													{CURRENCY_CONV.cryptoAmtConv(
														values?.received_amt,
													)}
												</td>
												<td
													onDoubleClick={() =>
														copyText(values.received_amt_curr)
													}
													className={classes.tagger_id}
												>
													{values.received_amt_curr}
												</td>
												<td>
													<button
														className={classes.view_btn}
														onClick={() => {
															openModal(() => (
																<PcryptoDepositDetails
																	transactionData={values}
																/>
															));
														}}
													>
														View Details
													</button>
												</td>
												<td
													className={
														values?.transc_status === 'approved'
															? 'textStatusApproved'
															: values?.transc_status === 'pending' ||
															  values?.transc_status === 'processing'
															? 'textStatusPending'
															: 'textStatusRejected'
													}
													onDoubleClick={() =>
														copyText(values.transc_status.toUpperCase())
													}
												>
													{values.transc_status.toUpperCase()}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					)
				}
			</div>
			{Change === 'Fiat' && <Paginate {...paginateProps} />}
			{Change !== 'Fiat' && <Paginate {...paginateProps2} />}
		</PartnerScreen>
	);
}
