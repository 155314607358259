import Button from 'components/Button';
import { ROUTES } from 'config';
import classes from 'styles/User.module.scss';
import Paginate from 'components/Paginate';
import Table from 'components/Table';
import { FilterListItem } from 'interfaces';
import useFilters from '../hooks/useFilters';
import { useState } from 'react';
import Filters from 'components/Filters';
import { copyText } from 'utils/CopyContent';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import arrowDownBlack from 'assets/arrow-down-black.svg';
import usePartnersPaginate from 'hooks/usePartnersPaginate';
import PartnerScreen from 'components/partnersScreen';
// import { usePartners } from 'context/partners';
import exportFromJSON from 'export-from-json';

const filterList: FilterListItem[] = [
  {
    field: 'kycStatus',
    label: 'KYC',
    options: [
      { value: true, label: 'Approved' },
      { value: false, label: 'Not Applied' },
    ],
  },
];

export default function Pusers() {
  const { filters, updateFilters } = useFilters();
  // const { headers } = usePartners();
  const [searchValue, setSearchValue] = useState<String>('');
  const [searchField, setSearchField] = useState<any>('name'); // ? name || phone_no || email

  const [searchValueSubmitted, setSearchValueSubmitted] = useState<String>('');
  const [users, { paginateProps, loading }] = usePartnersPaginate<any>(
    {
      route: ROUTES.Partners_FETCH_ALL_USERS,
      params: {
        filters: {
          [searchField]: searchValueSubmitted,
          ...filters,
        },
      },
      filterData: (data) => (data.data.usersList ? data.data.usersList : []),
    },
    [searchValueSubmitted, filters],
  );

  const handleSearchSubmit = (event: any) => {
    event.preventDefault();

    setSearchValueSubmitted(() => searchValue);
  };

  // ? Method to render the dropdown title
  const renderDropdownTitle = () => {
    switch (searchField) {
      case 'name':
        return 'Name';

      case 'phone_no':
        return 'Phone No';

      case 'email':
        return 'Email';
    }
  };
  // * End of renderDropdownTitle();

  const exportToExcel = (data: any, fileName: any, exportType: any) => {
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <PartnerScreen className={classes.container}>
      <div className={classes.usersTitleSection}>
        <h3>Users</h3>
        <button
          className={classes.ExportBtn}
          style={{ padding: '11px !important' }}
          onClick={() => {
            exportToExcel(users, 'Users', 'csv');
          }}
        >
          Export
        </button>
        <Filters
          filters={filters}
          updateFilters={updateFilters}
          filterList={filterList}
        />

        <form className={classes.userSearchForm} onSubmit={handleSearchSubmit}>
          <div className={classes.searchInputSection}>
            <input
              type="text"
              placeholder={
                searchField === 'name'
                  ? 'Search Name'
                  : searchField === 'email'
                    ? 'Search Email'
                    : 'Ph: 91 12345-67890'
              }
              onChange={(event) => setSearchValue(event?.target.value)}
            />
            <Menu
              menuButton={
                <MenuButton className={classes.dropdownBtn}>
                  <span>{renderDropdownTitle()}</span>
                  <img src={arrowDownBlack} alt="arrow-img" width={10} />
                </MenuButton>
              }
              transition
              menuClassName="drop-down"
              onItemClick={(e: any) => setSearchField(e.value)}
            >
              <MenuItem
                value="name"
                className={
                  searchField === 'name' ? 'dropdown-item-selected' : ''
                }
              >
                Name
              </MenuItem>
              <MenuItem
                value="phone_no"
                className={
                  searchField === 'phone_no' ? 'dropdown-item-selected' : ''
                }
              >
                Phone No.
              </MenuItem>
              <MenuItem
                value="email"
                className={
                  searchField === 'email' ? 'dropdown-item-selected' : ''
                }
              >
                Email
              </MenuItem>
            </Menu>
          </div>
          <Button loading={loading} type="submit">
            Search
          </Button>
        </form>
      </div>

      <Table loading={loading} height="75%">
        <thead>
          <tr>
            <th>ID</th>
            {/* <th>Phone no.</th> */}
            <th>Ref ID</th>
            <th>Email</th>
            <th>KYC Status</th>
          </tr>
        </thead>
        <tbody>
          {users.map(
            ({
              application_id,
              ref_partnerId,
              email,
              kyc_Status,
              _id,
              transc_fee,
            }) => (
              <tr key={_id}>
                <td onDoubleClick={() => copyText(_id)}>{_id}</td>
                <td onDoubleClick={() => copyText(ref_partnerId)}>
                  {ref_partnerId}
                </td>
                <td onDoubleClick={() => copyText(email)}>{email}</td>
                <td
                  onDoubleClick={() =>
                    copyText(
                      kyc_Status
                        ? 'Approved'
                        : !application_id
                          ? 'Not Applied'
                          : 'Rejected',
                    )
                  }
                  className={[
                    classes[
                    kyc_Status
                      ? 'approved'
                      : !application_id
                        ? 'notApplied'
                        : 'rejected'
                    ],
                    classes.status,
                  ].join(' ')}
                >
                  <p>
                    {kyc_Status
                      ? 'Approved'
                      : !application_id
                        ? 'Not Applied'
                        : 'Rejected'}
                  </p>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </Table>
      <div className={classes.foot}>
        <Paginate {...paginateProps} />
      </div>
    </PartnerScreen>
  );
}
