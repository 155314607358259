export const  flattenObject = (obj: any, parentKey = "", ignoreKeys : any[] = [])  => {
    let result: any = {};

    for (let key  in obj) {
        if (obj.hasOwnProperty(key) && !ignoreKeys.includes(key)) {
            const newKey = key;
            if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
                Object.assign(result, flattenObject(obj[key], newKey, ignoreKeys));
            } else {
                result[newKey] = obj[key];
            }
        }
    }

    return result;
}


















