import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { useState } from 'react';
import arrowDownBlack from 'assets/arrow-down-black.svg';
import Arrowdown from 'assets/Arrow - Down 4.png';
import classes from 'styles/Transaction.module.scss';
export const MenuDropDownFilter = ({
	menuList,
	onItemSelect,
	style,
}: {
	menuList: string[];
	style?: React.CSSProperties | undefined;
	onItemSelect: (e: any) => void;
}) => {
	const [selectedItem, setItem] = useState(menuList[0]);

	return (
		<Menu
			menuButton={
				<MenuButton className={classes.statusDropdown}>
					<span>{selectedItem}</span>
					<img src={Arrowdown} alt="arrow-img" width={10} />
				</MenuButton>
			}
			transition
			menuStyle={style}
			menuClassName="drop-down"
			onItemClick={(e: any) => {
				setItem(e.value);
				onItemSelect(e);
			}}
		>
			{menuList.map((option) => {
				return (
					<MenuItem
						value={option}
						className={
							selectedItem === option ? 'dropdown-item-selected' : ''
						}
					>
						{option}
					</MenuItem>
				);
			})}
		</Menu>
	);
};
