import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

interface Props {
	data?: any;
}

// eslint-disable-next-line
const data = [
	{ name: 'Withdrawal', value: 400 },
	{ name: 'Deposit', value: 300 },
];
const COLORS = ['#D5B54A', '#E0E0E0', '#2F70E8'];

export default function DoughnutChart(props: Props) {
	return (
		<PieChart width={350} height={200}>
			<Pie
				data={props.data}
				cx="50%"
				cy="50%"
				innerRadius={45}
				outerRadius={85}
				fill="#D5B54A"
				dataKey="value"
			>
				{props.data.map((entry: any, index: any) => (
					<Cell
						key={`cell-${index}`}
						fill={COLORS[index % COLORS.length]}
					/>
				))}
			</Pie>
			<Legend
				iconType="circle"
				layout="vertical"
				align="right"
				verticalAlign="middle"
				height={36}
			/>
			<Tooltip />
		</PieChart>
	);
}
