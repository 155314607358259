import Button from 'components/Button';
import { ROUTES } from '../config';
import classes from 'styles/User.module.scss';
import Paginate from 'components/Paginate';
import Table from 'components/Table';
// import { FilterListItem } from '../../interfaces';
import useFilters from 'hooks/useFilters';
import { useState } from 'react';
import { copyText } from 'utils/CopyContent';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import arrowDownBlack from 'assets/arrow-down-black.svg';
import usePartnersPaginate from 'hooks/usePartnersPaginate';
// import { usePartners } from 'context/partners';
import exportFromJSON from 'export-from-json';
import PartnerScreen from 'components/partnersScreen';

// const filterList: FilterListItem[] = [
//   {
//     field: 'kycStatus',
//     label: 'KYC',
//     options: [
//       { value: true, label: 'Approved' },
//       { value: false, label: 'Not Applied' },
//     ],
//   },
// ];

// const submitHandler = () => {
//   console.log('View Details button');
// };

export default function PwalletDetails() {
  const { filters } = useFilters();
  const [searchValue, setSearchValue] = useState<String>('');
  const [searchField, setSearchField] = useState<any>('name'); // ? name || phone_no || email
  // const { headers } = usePartners();

  const [searchValueSubmitted, setSearchValueSubmitted] = useState<String>('');
  const [wallets, { paginateProps, loading }] = usePartnersPaginate<any>(
    {
      route: ROUTES.Partners_WALLET_LIST,
      params: {
        filters: {
          [searchField]: searchValue,
          ...filters,
        },
      },
      filterData: (data) => (data.data.walletList ? data.data.walletList : []),
    },
    [searchValueSubmitted, filters],
  );


  const handleSearchSubmit = (event: any) => {
    event.preventDefault();

    setSearchValueSubmitted(() => searchValue);
  };

  // ? Method to render the dropdown title
  const renderDropdownTitle = () => {
    switch (searchField) {
      case 'name':
        return 'Name';
    }
  };
  // * End of renderDropdownTitle();
  const exportToExcel = (data: any, fileName: any, exportType: any) => {
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <PartnerScreen className={classes.container}>
      <div className={classes.usersTitleSection}>
        <h3>Wallet Details</h3>
        <button
          className={classes.ExportBtn}
          onClick={() => {
            exportToExcel(wallets, 'Wallet Deatils', 'csv');
          }}
        >
          Export
        </button>
        {/*<Filters
          filters={filters}
          updateFilters={updateFilters}
          filterList={filterList}
        />  */}

        <form className={classes.userSearchForm} onSubmit={handleSearchSubmit}>
          <div className={classes.searchInputSection}>
            <input
              type="text"
              placeholder={
                searchField === 'name'
                  ? 'Search Name'
                  : searchField === 'email'
                  ? 'Search Email'
                  : 'Ph: 91 12345-67890'
              }
              onChange={(event) => setSearchValue(event?.target.value)}
            />
            <Menu
              menuButton={
                <MenuButton className={classes.dropdownBtn}>
                  <span>{renderDropdownTitle()}</span>
                  <img src={arrowDownBlack} alt="arrow-img" width={10} />
                </MenuButton>
              }
              transition
              menuClassName="drop-down"
              onItemClick={(e: any) => setSearchField(e.value)}
            >
              <MenuItem
                value="name"
                className={
                  searchField === 'name' ? 'dropdown-item-selected' : ''
                }
              >
                Name
              </MenuItem>
            </Menu>
          </div>
          <Button loading={loading} type="submit">
            Search
          </Button>
        </form>
      </div>

      <Table loading={loading} height="75%">
        <thead>
          <tr>
            <th>Coin</th>
            <th>Address</th>
            <th>Network</th>
          </tr>
        </thead>
        <tbody>
          {wallets && wallets.map(({ coin, address, network }, index) => (
            <tr key={index}>
              <td>{coin}</td>
              <td onDoubleClick={() => copyText(`${network}%`)}>{address}</td>
              <td>{network}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className={classes.foot}>
        <Paginate {...paginateProps} />
      </div>
    </PartnerScreen>
  );
}
