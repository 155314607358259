import { useState } from 'react';

export default function useFilters() {
	const [filters, setFilters] = useState<any>({});
	const updateFilters = (key: string, value: string) =>
		setFilters((p: any) => ({
			...p,
			[key]: value === p[key] ? undefined : value,
		}));

	const removeFilters = (key: string) => {
		let updatedFilters = filters;
		if (updatedFilters[key]) {
			delete updatedFilters[key];
			console.log('deleted', updatedFilters);
			setFilters({ ...updatedFilters });
		}
	};

	return { filters, updateFilters, removeFilters };
}
