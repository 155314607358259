import './App.scss';
import { Switch, Route, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usePartners } from './context/partners';
import { useAdmin } from './context/admin';
import Pdashboard from 'pages/Pdashboard';
import Pusers from 'pages/Pusers';
import Pdeposits from 'pages/Pdeposits';
import PpayoutTransactions from 'pages/PpayoutTransactions';
import PRevenue from 'pages/PRevenue';
import Pwithdrawals from 'pages/Pwithdrawals';
import PbankDetails from 'pages/PbankDetails';
import PartnersHeader from 'components/PartnersHeader';
import AdminHeader from 'components/AdminHeader';
import PartnersLoginScreen from 'pages/PartnersLoginScreen';
import PwalletDetails from 'pages/PwalletDetails';
import PHelpCenter from 'pages/PHelpCenter';
import PStatement from 'pages/PStatement';
import PAdminAccess from 'pages/PAdminAccess';
import AdminLoginScreen from 'pages/AdminLoginScreen';
import PAdminDeposits from 'pages/PAdminDeposits';
import PAdminWithdrawals from 'pages/PAdminWithdrawals';
import PProviderConfig from 'pages/PProviderConfig';
import axios from 'axios';

axios.interceptors.request.use(
	(config: any) => {
		// Add your default headers here
		config.headers.common['X-Source'] = 'FRONTEND';
		return config;
	},
	(error: any) => {
		return Promise.reject(error);
	}
);

toast.configure({ theme: 'colored', position: 'top-center' });
export default function App() {
	const { Partner, Ptoken } = usePartners();
	const { Admin, Atoken } = useAdmin();
	/**update socket-client */
	// console.log(Partner, Ptoken)
	return (
		<div className="App">
			<Switch>

				<Route exact path="/" component={PartnersLoginScreen} />

				<Route>
					{Partner && Ptoken && <PartnersHeader />}
					{Atoken && <AdminHeader />}
					<Switch>
						<Route exact path="/admin" component={AdminLoginScreen} />
						<Route path="/">

							{Partner && Ptoken && (
								<Switch>
									<Route path="/dashboard" exact component={Pdashboard} />
									<Route path="/users" exact component={Pusers} />
									<Route path="/deposits" exact component={Pdeposits} />
									<Route
										path="/payout_transactions"
										exact
										component={PpayoutTransactions}
									/>
									<Route
										path="/wallet_details"
										exact
										component={PwalletDetails}
									/>
									<Route
										path="/withdrawal"
										exact
										component={Pwithdrawals}
									/>
									<Route
										path="/bank_details"
										exact
										component={PbankDetails}
									/>
									<Route
										path="/revenue_report"
										exact
										component={PRevenue}
									/>
									<Route
										path="/statement_account"
										exact
										component={PStatement}
									/>
									<Route
										path="/help_center"
										exact
										component={PHelpCenter}
									/>
									{/* Remove Admin Access and Providers Config */}
									<Route
										path="/admin_access"
										exact
										component={PAdminAccess}
									/>
									<Route
										path="/provider_config"
										exact
										component={PProviderConfig}
									/>
								</Switch>
							)}
							{Atoken && (
								<Switch>
									<Route
										path="/admin_access"
										exact
										component={PAdminAccess}
									/>
									{/* Keep only Revenue, statement and Admin Acess*/}
									{/* <Route
										path="/payout_transactions"
										exact
										component={PpayoutTransactions}
									/> */}
									<Route
										path="/admin_deposits"
										exact
										component={PAdminDeposits}
									/>
									<Route
										path="/admin_withdrawal"
										exact
										component={PAdminWithdrawals}
									/>
									<Route
										path="/provider_config"
										exact
										component={PProviderConfig}
									/>
								</Switch>
							)}
						</Route>
					</Switch>
				</Route>
			</Switch>
		</div>
	);
}
