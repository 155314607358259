import axios from 'axios';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { ROUTES } from '../config';
import useLocalStorage from '../hooks/useLocalStorage';
import { ANY, LoginForm, PartnerRegisterForm } from '../interfaces';

// Interface++++++++++++
const PartnersContext = createContext({
	Partner: ANY,
	Ptoken: ANY,
	login: async (
		data: LoginForm,
		ifLogged?: () => any,
		// ifOTP?: (email: string) => any,
	) => ANY,
	register: async (data: PartnerRegisterForm, cb?: () => any) => ANY,
	logout: async () => ANY,
	// sendOTP: async (formData: OTPForm, cb?: () => any) => ANY,
	headers: ANY,
	getPartner: async () => ANY,
});

export const PartnersProvider: FC = ({ children }) => {
	const [Partner, setPartner] = useState<any>();
	const [Ptoken, PsetToken] = useLocalStorage<string | null>(
		'Ptoken',
		null,
	);
	const history = useHistory();
	const headers = { authorization: `Bearer ${Ptoken}` };
	const TOKEN_ROTATION_DURATION = 2700000;

	const getPartner = async () => {
		if (!Ptoken) return setPartner(null);
		try {
			axios.defaults.withCredentials = false;
			const { data } = await axios.get(ROUTES.Partners_Info, {
				headers: { authorization: `Bearer ${Ptoken}` },
			});
			if (!data) return;
			setPartner(data.data.info);
			// console.log(data.data,"herer")
			localStorage.setItem(
				'PartnerEmail',
				data?.data.info.clientRepresentiveEmail,
			);
			// history.push('/dashbaord')
		} catch (error: any) {
			setPartner(null);
			if (
				error?.response?.status === 401
				// history.location.pathname !== 'partner/verifiedEmail'
			)
				history.push('/');
		}
	};

	// ? Method that is used to request a Refresh Token from the backend and set it in LocalStorage
	const setRefreshToken = async () => {
		try {
			let email = localStorage.getItem('PartnerEmail');

			const { data }: any = await axios.post(
				ROUTES.Partners_Refresh_Token,
				{ email },
				{
					headers: { authorization: `Bearer ${Ptoken}` },
				},
			);

			// ? Set the newly generated Refresh Token in LocalStorage
			PsetToken(data.accessToken);
		} catch (error) {
			logout();
		}
	};
	// * End of setRefreshToken();

	useEffect(() => {
		getPartner();

		// ? Checks if Access Token is set
		// ? # If true, setRefreshToken() is called every 'TOKEN_ROTATION_DURATION' seconds
		if (Ptoken) {
			const interval = setInterval(() => {
				setRefreshToken();
			}, TOKEN_ROTATION_DURATION);

			return () => clearInterval(interval); // ? Clearning the Interval to prevent Memory Leaks
		}

		// eslint-disable-next-line
	}, [Ptoken]);

	// console.log({ Ptoken });

	const login = async (
		{ email, password }: LoginForm,
		ifLogged?: () => any,
	) => {
		try {
			//axios.defaults.withCredentials = false;
			const { data }: any = await axios.post(`${ROUTES.Partners_Login}`, {
				partnerEmail: email,
				partnerPassword: password,
			});
			const { access_token } = data.data;
			if (access_token) {
				history.push('/dashboard');
				PsetToken(access_token);
				ifLogged?.();
				return { success: `Logged In!` };
			}
		} catch (error: any) {
			toast.warning(error?.response?.data?.message);
			return { error: error?.response?.data?.message };
		}
	};

	const register = async (
		formData: PartnerRegisterForm,
		cb?: () => any,
	) => {
		try {
			const { data }: any = await axios.post(ROUTES.Partners_Registor, {
				// ...formData,
				enterpriseName: formData.enterpriseName,
				clientRepresentiveEmail: formData.email,
				clientRepresentivePassword: formData.password,
				clientRepresentivePhoneNo: formData.phone_no,
				enterpriseLocation: formData.location,
				grant_services: ['FORENSICS'],
			});

			if (data.accessToken) {
				cb?.();
				PsetToken(data.accessToken);
				history.push('/dashboard');
				return {
					success: `Welcome to Encryptus, ${formData.enterpriseName}!`,
				};
			}
		} catch (error: any) {
			if (error.response.status === 400) {
				toast.warn(error.response.data.message);
			} else {
				toast.warn('An Error Occured');
			}
			return { error: error?.response?.data?.message };
		}
	};

	const logout = async () => {
		try {
			axios.defaults.withCredentials = false;
			const { data }: any = await axios.get(ROUTES.Partners_Logout, {
				headers,
			});
			PsetToken(null);
			localStorage.removeItem('PartnerEmail');
			console.log({ xx: data });
			if (data.data.loggedOut) {
				console.log('true');
				history.push('/');
				toast.success('Logged Out');
			}
		} catch (error: any) {
			return { error: error?.response?.data?.message };
		}
	};

	// const sendOTP = async (formData: OTPForm, cb?: () => any) => {
	//     try {
	//         const { data }: any = await axios.post(ROUTES.OTP, formData);
	//         if (data.accessToken) {
	//             PsetToken(data.accessToken);
	//             cb?.();
	//             history.push('partners/dashboard');

	//             return { success: `Logged In!` };
	//         }
	//     } catch (error: any) {
	//         console.log({ error });
	//         toast.warn('Please Enter Valid OTP');
	//         return { error: error?.response?.data?.message };
	//     }
	// };

	return (
		<PartnersContext.Provider
			value={{
				Partner,
				Ptoken,
				login,
				register,
				logout,
				headers,
				// sendOTP,
				getPartner,
			}}
		>
			{children}
		</PartnersContext.Provider>
	);
};

export const usePartners = () => useContext(PartnersContext);
