// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useAdmin } from 'context/admin';
import classes from '../styles/WithdrawDetailsModal.module.scss';
import { DATE_CONV } from 'utils/DateConv';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { copyText } from 'utils/CopyContent';
// import { ROUTES } from '../config';

function PcryptoWithdrawDetails({ transactionData }: any) {
	return (
		<div className={classes.detailsContainer}>
			<h1>Crypto Withdraw Details</h1>
			<section className={classes.box}>
				<>
					<div>
						<h4>Transaction Reference ID</h4>
						<p
							onDoubleClick={() => copyText(transactionData?.unq_ref_id)}
							style={{
								paddingRight: '30px',
							}}
						>
							{transactionData?.unq_ref_id}
						</p>
					</div>
					<div>
						<h4>Transaction Status</h4>
						<p
							onDoubleClick={() =>
								copyText(transactionData?.transc_status?.toUpperCase())
							}
							className={
								transactionData?.transc_status === 'approved'
									? 'textStatusApproved'
									: transactionData?.transc_status === 'pending'
									? 'textStatusPending'
									: 'textStatusRejected'
							}
						>
							{transactionData?.transc_status?.toUpperCase()}
						</p>
					</div>
					{/* <div>
            <h4>Quoted Amount</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  CURRENCY_CONV.fiatAmtConv(transactionData?.quot_amount),
                )
              }
            >
              {CURRENCY_CONV.fiatAmtConv(transactionData?.quot_amount)}
            </p>
          </div> */}
					<div>
						<h4>Withdraw Amount</h4>
						<p
							onDoubleClick={() =>
								copyText(
									CURRENCY_CONV.cryptoAmtConv(
										transactionData?.withdraw_amt,
									),
								)
							}
						>
							{CURRENCY_CONV.cryptoAmtConv(transactionData?.withdraw_amt)}
						</p>
					</div>
					<div>
						<h4>Currency</h4>
						<p
							onDoubleClick={() =>
								copyText(
									transactionData?.fiat_curr ||
										transactionData?.withdraw_curr?.toUpperCase(),
								)
							}
						>
							{transactionData?.fiat_curr ||
								transactionData?.withdraw_curr?.toUpperCase()}
						</p>
					</div>
					<div>
						<h4>Flagged</h4>
						<p
							onDoubleClick={() =>
								copyText(!transactionData?.flagged ? 'CLEAN' : 'DIRTY')
							}
							className={
								!transactionData?.flagged === true
									? 'textStatusApproved'
									: 'textStatusRejected'
							}
						>
							{!transactionData?.flagged ? 'CLEAN' : 'DIRTY'}
						</p>
					</div>
					<div>
						<h4>Transaction Date - Time</h4>
						<p
							onDoubleClick={() =>
								copyText(
									DATE_CONV.dateTimeConvert(transactionData?.createdAt),
								)
							}
						>
							{DATE_CONV.dateTimeConvert(transactionData?.createdAt)}
						</p>
					</div>
				</>
			</section>
		</div>
	);
}

export default PcryptoWithdrawDetails;
