import React, { useState } from 'react';
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { anyObject, FormProp } from '../interfaces';
import classes from 'styles/InputField.module.scss';
import ReactPhoneInput from './ReactPhoneInput';


interface Props {
    entry: FormProp;
    register: UseFormRegister<FieldValues>;
    errors: anyObject;
    setValue: UseFormSetValue<FieldValues>;
    Bgcolor?: string;
    color?: string;
    disabled?: boolean;
}

export default function InputField({
    entry,
    register,
    errors,
    setValue,
    Bgcolor,
    color,
    disabled,
}: Props) {
    const [currentType, setType] = useState<string | undefined>(
        !(entry instanceof Array) ? entry.type : undefined,
    );

    if (entry instanceof Array)
        return (
            <div className={classes.flex}>
                {entry.map((e) => (
                    <div key={e.name}>
                        <InputField
                            entry={e}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            color={color}
                            Bgcolor={Bgcolor}
                            disabled={disabled}
                        />
                    </div>
                ))}
            </div>
        );
    const {
        name,
        icon: Icon,
        label,
        type,
        toolIcon: ToolIcon,
        placeholder,
        options = [],
        phoneNumberInput: PhoneNumberInput,
        value,
        country,
    } = entry;
    const togglePasswordVisibility = () => {
        setType((t) => (t === 'password' ? 'text' : 'password'));
    };

    const handleTool = () => {
        if (type === 'password') togglePasswordVisibility();
    };

    return (
        <div
            className={type !== 'checkbox' ? classes.container : classes.container2}
        >
            <label style={{ color: color }}>{label}</label>

            {type === 'tel' && PhoneNumberInput ? (
                <ReactPhoneInput setValue={setValue} country={country} />
            ) : name === 'walletAddress' || name === 'inputLabel' ? (
                <div className={classes.inputContainer2}>
                    <input
                        {...register(name)}
                        id="clean"
                        style={{ background: Bgcolor }}
                        value={value}
                        defaultValue={value}
                        placeholder={placeholder}
                        type={currentType || 'text'}
                    />
                </div>
            ) : (
                <div
                    className={[classes.inputContainer, classes[type || '']].join(' ')}
                >
                    <div className={classes.icon}>
                        {Icon &&
                            (typeof Icon === 'string' ? (
                                <img src={Icon} alt={`${name} icon`} />
                            ) : (
                                <Icon />
                            ))}
                    </div>
                    {!PhoneNumberInput && (
                        <input
                            {...register(name)}
                            id="clean"
                            value={value}
                            defaultValue={value}
                            style={{ color: color, background: Bgcolor }}
                            placeholder={placeholder}
                            type={currentType || 'text'}
                        />
                    )}

                    <div onClick={handleTool} className={classes.tool}>
                        {ToolIcon &&
                            (typeof ToolIcon === 'string' ? (
                                <img src={ToolIcon} alt={`${name} tool`} />
                            ) : (
                                <ToolIcon />
                            ))}
                    </div>
                </div>
            )}
            {errors[name] && <p className={classes.error}>{errors[name]?.message}</p>}
        </div>
    );
}
